import React from 'react'
import {NavDropdown} from 'react-bootstrap';
import {Link} from "react-router-dom";
import UrlResolver from "../../../service/UrlResolver";

const UserDropdown = (props) => {
    const initials = (name) => {
        if (!name) {
            return ""
        }
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
        return initials;
    }

    const handleLogout = (event) => {

        localStorage.removeItem("jwt");
        localStorage.removeItem("remail");
        localStorage.clear();
        window.location.assign("/")
    }

    let navDropdownTitle = null;

    if (props.user && props.user.image) {
        navDropdownTitle = (
            <img className="icon46" src={props.user ? UrlResolver.generateMediaUrl(props.user.image) : ''} alt="icon"/>)
    } else {
        navDropdownTitle = (<span className="icon46 text">{props.user && (initials(props.user.name))}</span>)
    }

    return (
        <NavDropdown title={navDropdownTitle} className="userDropdown" id="basic-nav-dropdown">
            <DropItem name="Account Settings" link="/profile"/>
            <DropItem name="Saved/My List" link="/watchlist"/>
            {/* <DropItem name="Logout" link="1"/> */}
            <Link onClick={handleLogout} class="dropdown-item">Logout </Link>
        </NavDropdown>
    )
}

const DropItem = (props) => {
    return (
        <NavDropdown.Item href={props.link}>{props.name}</NavDropdown.Item>
    )
}
export default UserDropdown;
