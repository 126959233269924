import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import ButtonRound from "../../../components/buttons/ButtonRound";
import UrlResolver from "../../../../service/UrlResolver";

import {connect} from "react-redux";

const InterestSecton = (props) => {
    return (
        <div className="interestSection">
            <Container>
                <div className="interestHeading landingSectionHeading">
                    <h2>
                        Upgrade every area of your life, mental, emotional,<br/> physical, relationships, finance<br/>
                        Achieve your Telos!
                    </h2>
                    <ul>
                        <li>Become your best possible future self</li>
                        <li>Reach your greatest potential</li>
                        <li>Remove self-limiting beliefs</li>
                    </ul>
                </div>
                <Row className="mb-3 mt-3 align-items-center justify-content-center">
                    {props.categories.map(category => {
                        return <InterestThumb key={category.id}
                                              thumb={UrlResolver.generateMediaUrl(category.HomeImage)}
                                              title={category.title}
                        />
                    })}


                </Row>
                <div className="d-flex justify-content-center">

                    {!props.user.jwt ?
                        <ButtonRound
                            link="/auth/register"
                            name="Get Started"
                        />
                        :
                        <ButtonRound
                            link="/home"
                            name="Get Started"
                        />
                    }
                </div>
            </Container>
        </div>
    );
}

const InterestThumb = (props) => {
    return (
        <Col md={6} sm={6} xs={6} xl={4}>
            <div className="interestThumb">
                {props.thumb && props.thumb !== null ?
                    <img src={props.thumb} alt="thumb"/>
                    :
                    <img src="../../../../assets/landing/interest-thumb.png"/>

                }

                <div className="interestTitle">
                    <h3>{props.title}</h3>
                </div>
            </div>
        </Col>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(InterestSecton);

