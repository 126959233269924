import React, {useEffect, useState} from 'react';
import DefaultInput from "../../components/inputs/DefaultInput";
import {Link, useHistory} from "react-router-dom";
import DefaultButton from "../../components/buttons/DefaultButton";
import {Formik} from "formik";
import {Api} from "../../../service/Api";
import {connect} from "react-redux";

const Login = (props) => {
    const [failed, setFailed] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (props.user.user) {
            history.push("/home");
        }
    }, [history, props.user.user]);


    return (
        <div className="p-5">
            <div className="authTitle">
                <h1>Login</h1>
            </div>

            <Formik
                initialValues={{identifier: '', password: ''}}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors.password = 'Required';
                    }
                    if (!values.identifier) {
                        errors.identifier = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, {setSubmitting}) => {
                    Api.login(values).then(user => {
                        props.login(user);
                        setTimeout(() => {
                            history.push('/home');
                        }, 1000)

                    }).catch((e) => {
                        setFailed({error: e.response.data.message[0].messages[0].message});
                    })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <DefaultInput
                            type="email"
                            error={errors.identifier}
                            name="identifier"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email"
                            value={values.identifier}
                        />
                        <DefaultInput error={errors.password}
                                      placeholder="Password"
                                      type="password"
                                      name="password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                        />
                        {failed.error && <div className="donthave-acc mt-3"><h4>{failed.error}</h4></div>}
                        <DefaultButton type={'submit'} disabled={isSubmitting} name={'Sign In'}/>
                    </form>
                )}
            </Formik>
            <div className="d-flex flex-row justify-content-between align-items-center my-3">
                <Link to="/auth/forgot" className="action">Forgot Password?</Link>
            </div>

            <div className="ifGoNext">
                <span>New to Telos?</span>
                <Link to="/auth/register">Register Now</Link>
            </div>
            
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
