import React, { useRef } from "react";
import PlayerButton from "../../../components/buttons/PlayerButton";
import Vimeo from "@u-wave/react-vimeo";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const VideoPlayer = (props) => {

    const ref = useRef();

    return (
        <div className="videoPlayer">
            {props.video &&
                <div className="video-box">

                    <Vimeo
                        onTimeUpdate={props.onTimeUpdate}
                        video={props.video}
                        autoplay
                    />


                </div>
            }

            {props.audio &&
                <div className="video-box">
                    <img src={props.thumb} alt="thumb" />
                    <AudioPlayer
                        autoPlay
                        ref={ref}
                        src={props.audio}
                        onListen={() => {
                            props.onTimeUpdate({
                                seconds: ref.current.audio.current.currentTime,
                                percent: ref.current.audio.current.currentTime / ref.current.audio.current.duration
                            });
                        }}
                    // other props here
                    />


                </div>
            }

            {!props.video && !props.audio &&
                <> <img src={props.thumb} alt="thumb" />
                    <div className="thumbOverlayDetail video-detailed">
                        <div className="videoTitle">
                            {/* <h3>{props.title}</h3> */}
                            {props.category &&
                                <p>{props.category}</p>
                            }
                        </div>
                        <div className="d-flex justify-content-start">
                        <PlayerButton onClick={props.onPlay}
                                    icon="../../../../assets/img/play-ico.svg"
                                // name="Play Now"
                                />
                           
                            <div className="me-2" />
                            <PlayerButton
                                    onClick={props.onPlayAudio}
                                    icon="../../../../assets/img/music-ico.png"
                                    // name="Audio"
                                />
                            
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default VideoPlayer;
