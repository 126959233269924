import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import DefaultInput from "../../components/inputs/DefaultInput";
import {Link, useHistory} from "react-router-dom";
import DefaultButton from "../../components/buttons/DefaultButton";
import {Formik} from "formik";
import {Api} from "../../../service/Api";
import {connect} from "react-redux";

const Register = (props) => {


    let landing_email = localStorage.getItem('remail');
    let LandingEmail = '';
    if (landing_email) {
        console.log(landing_email);
        LandingEmail = landing_email;
    }


    const [failed, setFailed] = useState({});
    const history = useHistory();


    return (
        <div className="p-5">
            <div className="authTitle">
                <h1>Sign Up</h1>
            </div>
            <Formik
                initialValues={{name: '', username: LandingEmail, password: ''}}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.name) {
                        errors.name = 'Required';
                    }
                    if (!values.password) {
                        errors.password = 'Required';
                    }
                    if (!values.username) {
                        errors.username = 'Required';
                    } 
                    return errors;
                }}
                onSubmit={(values, {setSubmitting}) => {
                    Api.register({...values, email: values.username}).then(user => {
                        localStorage.setItem('jwt', user.jwt);
                        props.login(user);
                        console.log(user);
                        setTimeout(() => {
                            history.push('/myInterests?next=membership');
                        }, 1000)
                    }).catch(err => {
                        console.log(err.response.data);
                        setFailed({error: err.response.data.message[0].messages[0].message});

                    })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <DefaultInput error={errors.name} type="text" name="name" onChange={handleChange}
                                      onBlue={handleBlur} value={values.name}
                                      placeholder="Full Name"/>
                        <DefaultInput
                            type="email" error={errors.username}
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email"
                            value={values.username}
                        />
                        <DefaultInput error={errors.password}
                                      placeholder="Password"
                                      type="password"
                                      name="password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                        />
                        {failed.error && <div className="donthave-acc mt-3"><h4>{failed.error}</h4></div>}
                        <DefaultButton type={'submit'} disabled={isSubmitting} name={'Sign Up'}/>
                    </form>
                )}
            </Formik>

            <Form>


                <div className="ifGoNext mt-4">
                    <span>Already have an account?  </span>
                    <Link to="/auth">Login</Link>
                </div>
                
            </Form>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register)
