import React from 'react'
import ButtonRound from "../../../buttons/ButtonRound";

const ErrorModal = (props) => {
    return (
        <div className="statusModal errorModal">
            <div className="modalHeader">
                <div className="modalIcon">
                    <img src="../../../../assets/img/check.png" alt="icon"/>
                </div>
                Sorry
            </div>
            <div className="modalBody">
                <p>Your payment has been failed, please check the details and try again!</p>
            </div>
            <div className="modalFooter">
                <ButtonRound
                    name="Done"
                />
            </div>
        </div>
    );
}

export default ErrorModal;
