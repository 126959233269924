import React, {useCallback} from 'react'
import CommentAction from "./components/CommentAction";
import {Api} from "../../../../service/Api";

const CommentCard = ({refresh, ...props}) => {

    let {comments} = props.video;

    const like = useCallback(
        (id) => {
            Api.likeComment(id).then(() => {
                refresh();
            }).catch(e => {

            })
        },
        [refresh],
    );


    return (
        <>
            {comments.reverse().map(comment => {
                return <div className="d-flex ">
                    <User user="../../../../assets/img/user.png"/>
                    <div className="d-flex flex-column ps-lg-4">
                        <div className="d-flex align-items-center">
                            <h2 className="me-4">{props.userName}</h2>
                            <span className="time">{props.time}</span>
                        </div>
                        <p>{comment.comment}</p>
                        <CommentAction refresh={props.refresh} id={comment.id} like={() => {
                            like(comment.id)
                        }} likes={comment.likedByUsers.length}/>
                    </div>
                </div>
            })}
        </>
    )
}

const User = (props) => {
    return (
        <img src={props.user} className="avatar" alt="user"/>
    )
}

export default CommentCard;
