import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Link} from "react-router-dom";
import UrlResolver from '../../../../service/UrlResolver';

const CateogrySlide = (props) => {

    if (!props.categories || props.categories.length === 0) {
        return <></>
    }
    return (
        <OwlCarousel
            className='owl-theme category'
            loop={false} dots={false} nav={true} navContainerClass="d-none"
            margin={10}
            stagePadding={50}
            responsive={{
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                900: {
                    items: 2
                },
                1000: {
                    items: 5
                }
            }}
        >
            {props.categories.map(category => {
                return <CarouselItem
                    title={category.title}
                    thumb={UrlResolver.generateBlogMediaUrl(category.image.url)}
                    vid={category.id}
                />
            })}

        </OwlCarousel>
    )
}

const CarouselItem = (props) => {
    return (
        <Link to={`/videoCategory/${props.vid}`} className='item'>
            <img src={props.thumb} alt="thumb"/>
            <div className="thumbOverlay">
                <div className="videoTitle ttt">
                    <h3>{props.title}</h3>
                </div>
            </div>
        </Link>
    )
}

export default CateogrySlide;
