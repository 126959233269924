import React, {useState} from 'react';
import DefaultInput from "../../components/inputs/DefaultInput";
import {Link} from "react-router-dom";
import DefaultButton from "../../components/buttons/DefaultButton";
import {Formik} from 'formik';
import {Api} from '../../../service/Api';
import customConfirmDialog from '../../components/CustomConfirmDialog';

const Forgot = () => {

    const [failed, setFailed] = useState({});
    return (
        <div className="p-5">
            <Formik
                initialValues={{email: ''}}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (
                        !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i.test(values.email)
                        ) {
                            errors.email = 'Invalid email address';
                        }
                    return errors;
                }}


                onSubmit={(values, {setSubmitting}) => {
                    Api.forgetPassword({...values, email: values.email}).then(() => {
                            customConfirmDialog("Success!", "Email has sent on entered email address", () => {

                            }, "Okay")
                        }
                    ).catch(err => {
                        //console.log(err.response.data);
                        setFailed({error: err.response.data.message[0].messages[0].message});

                    }).finally(() => {
                        setSubmitting(false)
                    })
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="authTitle">
                            <h1>Forgot Password</h1>
                        </div>
                        <DefaultInput
                            type="email" error={errors.email}
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email Address"
                            value={values.email}
                        />
                        {failed.error && <div className="donthave-acc mt-3"><h4>{failed.error}</h4></div>}
                        <DefaultButton type={'submit'} disabled={isSubmitting} name={'Submit'}/>

                        <div className="ifGoNext mt-4">
                            <span>Back to</span>
                            <Link to="/auth">Login</Link>
                        </div>
                        <p>
                            We will send you instructions on how to reset your password by email.
                        </p>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default Forgot;
