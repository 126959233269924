import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import SmallVideoThumb from "../../components/sections/smallVideoThumb/SmallVideoThumb";
import {Api} from '../../../service/Api';
import UrlResolver from "../../../service/UrlResolver";

const VideoCategories = (props) => {

    const [categories, setCategories] = useState([]);


    useEffect(() => {
        Api.getCategories().then(response => {
            setCategories(response)
        })

    }, []);

    if (!categories) {
        return <></>
    }
    return (
        <div className="blogs">
            <Container>
                {/* <SectionHeading
                    title={catvideos.title}
                /> */}
                <Row>
                    {categories.map(category => {
                        return <Col md={3} >
                            <Link  to={`/videoCategory/${category.id}`}>
                                <SmallVideoThumb
                                    thumb={UrlResolver.generateBlogMediaUrl(category.image.url)}
                                    title={category.title}
                                />
                            </Link>
                        </Col>
                    })}
                </Row>
            </Container>
        </div>
    );
}
export default VideoCategories;
