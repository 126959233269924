import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ButtonSmRound from "../../components/buttons/ButtonSmRound";
import { Api } from '../../../service/Api';
import UrlResolver from "../../../service/UrlResolver";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Moment from 'moment';

const BlogDetail = (props) => {

    const [bdetail, setBdetail] = useState(null);
    const [catblogs, setCatblogs] = useState(null);

    let { id } = useParams();

    useEffect(() => {
        Api.getBlogdetail(id).then(response => {
            //console.log(response);
            setBdetail(response);

            if (response) {
               // console.log(response.blog_category);
                let catId = response.blog_category.id;

                console.log(catId);

                Api.getBlogsbycategory(catId).then(response => {
                    console.log(response);
                    setCatblogs(response.filter(tblog => {
                        return tblog.id != id;
                    }));
                })
            }
        })

    }, []);

    if (!bdetail) {
        return <></>
    }

    if (!catblogs) {
        return <></>
    }

    return (
        <div className="blogs blogThumb">

            <Container>
                <div className="blog-image mb-3">
                    {bdetail.MainImage && bdetail.MainImage !== null ?
                        <img src={UrlResolver.generateBlogMediaUrl(bdetail.MainImage.url)}
                            alt={bdetail.MainImage.name} />
                        :
                        <img src="../../../assets/home-images/wake-up.png" alt="blog images" />
                    }
                    <div className="mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <h5 className="me-2">{bdetail.blog_category.name}</h5>
                                <img className="show-line" src="../../../../assets/img/show-line.png" alt="icon" />
                            </div>
                            <span>{Moment(bdetail.date).format('MMMM DD,YYYY')}</span>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col md={8}>
                        <h3 className="blog-title">{bdetail.title}</h3>
                        <div className="blog-description">
                            <ReactMarkdown children={bdetail.description} rehypePlugins={[rehypeRaw]}   transformImageUri={uri =>
        uri.startsWith("http") ? uri : `https://backend.telos.itmatic.io${uri}`
      }/>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='blog-detail-related'>
                            <div className="d-flex flex-wrap">
                                {bdetail.tags.map((tag) => {
                                    return <ButtonSmRound mr={true} name={tag.tags} />
                                })}
                                {/* <ButtonSmRound mr={true} name="# Social"/>
                            <ButtonSmRound mr={true} name="# Physical Health"/>
                            <ButtonSmRound mr={true} name="# Financial Health"/>
                            <ButtonSmRound mr={true} name="# Emotional Health"/> */}
                            </div>
                            <div className="relatedBlogs mt-3">
                                <h3 className="similar-blog-title">Similar Blogs</h3>
                                {catblogs.map((blog) => {
                                    return <div className=" blog-related"><a
                                        className="w-100 d-flex flex-row align-items-center"
                                        href={`/blogDetail/${blog.id}`}>
                                        <img src={UrlResolver.generateBlogMediaUrl(blog.thumbnail.url)}
                                            alt={blog.thumbnail.name} />
                                        <p className="ms-2">{blog.title}</p>
                                    </a>
                                    </div>
                                })}
                                {/* <div className="d-flex flex-row align-items-center">
                                <img src="../../../../assets/thumbs/video-detail/yoga.jpg" alt="thumb"/>
                                <p className="ms-2">The Revolutionary Functionality of the Doona Car Seat & Stroller</p>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <img src="../../../../assets/thumbs/video-detail/yoga.jpg" alt="thumb"/>
                                <p className="ms-2">The Revolutionary Functionality of the Doona Car Seat & Stroller</p>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <img src="../../../../assets/thumbs/video-detail/yoga.jpg" alt="thumb"/>
                                <p className="ms-2">The Revolutionary Functionality of the Doona Car Seat & Stroller</p>
                            </div> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BlogDetail;
