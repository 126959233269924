import React from 'react'
import {Container, Navbar} from 'react-bootstrap';
import {Link} from "react-router-dom";
import ButtonSm from "../buttons/ButtonSm";

const HeaderWithoutLogin = (props) => {
    return (
        <header className="header">
            <DefaulHeader
                logo="../../../../assets/img/logo-text.png"
            />
        </header>
    )
}

const DefaulHeader = (props) => {
    return (
        <Navbar bg="dark" expand="lg" className=" headerWithputLogin">
            <Container>
                <div className="w-100 d-flex align-items-center justify-content-center" style={{position:'relative'}}>
                    <div className="onMobile"/>
                    <Link className="mobile-logo" to="/">
                        <img  src={props.logo} alt="logo"/>
                    </Link>
                    <div className='landing-auth-buttons'>
                    <ButtonSm link="/auth"
                              name="Log in"
                    />
                    <ButtonSm link="/auth/register"
                              name="Register"
                    />
                    </div>
                </div>
            </Container>
        </Navbar>
    )
}
export default HeaderWithoutLogin;
