import React, { useCallback, useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container } from "react-bootstrap";
import IconButton from "../../buttons/IconButton";
import IconBorderButton from "../../buttons/IconBorderButton";
import DarkBadges from "../../badges/DarkBadges";
import UrlResolver from '../../../../service/UrlResolver';
import Helper from "../../../../service/Helper";
import Moment from 'moment';
import { Api } from "../../../../service/Api";
import { connect } from "react-redux";

const MainSlider = (props) => {


    if (!props.fvideos || props.fvideos.length === 0) {
        return <></>
    }
    return (
        <Container className="position-relative">
            <OwlCarousel
                className='owl-theme mainSlider'
                loop dots={true} nav={true}
                navContainerClass="owl-arrows"
                loop={true}
                margin={1}
                items={1}
                autoplay={true}
            >
                {props.fvideos.map(video => {
                    return <CarouselItemConnected
                        title={video.title}
                        subtitle={`${video.category.title} • ${Moment(video.created_at).format('YYYY')} • 2h 35m`}
                        detail={Helper.shortenString(video.description)}
                        slideBack={video.large_thumbnail ? UrlResolver.generateBlogMediaUrl(video.large_thumbnail.url) : '../../../../assets/home-images/wake-up.png'}
                        videoId={video.id}
                        seek={video}
                        videoTags={video.tags}
                    />
                })}
            </OwlCarousel>
        </Container>
    )
}

const CarouselItem = ({ user, seek, ...props }) => {


    const [inWishlist, setInWishlist] = useState(false);

    useEffect(() => {
        setInWishlist(user?.wishlistVideos?.indexOf(seek.id) > -1);
    }, [user, seek]);


    const addToWishlist = useCallback(() => {
        setInWishlist(!inWishlist);
        Api.addToWishlist(seek.id).then((user) => {
        });
    }, [seek, inWishlist])

    return (
        <div className='item' style={{ backgroundImage: "url(" + props.slideBack + ")" }}>
            <div className="slideItemContainer">
                <div className="slideItem" style={{ justifyContent: 'flex-end', marginLeft: 45 }}>
                    {/* <h1>{props.title}</h1>
                    <h3>{props.subtitle}</h3> */}
                    <p className="mt-2 text-light">{props.detail}</p>
                    <div className="d-flex flex-row mt-5 main-slider-mobile">
                        
                            <IconButton
                                link={`/videoDetail/${props.videoId}`}
                                icon="../../../../../assets/img/play-button.svg"
                                // name="Play Now"
                            />
                        
                        

                        <div className="me-2" />
                            {inWishlist ? <IconBorderButton onClick={addToWishlist}
                                icon="../../../../../assets/img/tick.svg"
                                // name="Added"
                            /> : <IconBorderButton onClick={addToWishlist}
                                icon="../../../../../assets/img/watch-new.svg"
                                // name="Watchlist"
                            />}
                        
                        
                    </div>
                    <h3 className="mobileSub">{props.subtitle}</h3>
                    <div className="mt-3 slideBadge">
                        {props.videoTags.map((tag) => {
                            return <DarkBadges badge={tag.title} link={`/videoTags/${tag.title}`} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const CarouselItemConnected = connect(mapStateToProps, mapDispatchToProps)(CarouselItem);
export default MainSlider;
