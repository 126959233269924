import React from 'react';
import {connect} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import Stripe from "../Stripe";

const stripePromise = loadStripe('pk_test_nCcWEWsgJJTgFQ0DnB6h3PKj');

const PayCardModal = (props) => {

    if (!props.planDone) {
        return <></>
    }
    return (
        <div className="paymentModal payCard">
            <div className="modalHeader justify-content-between">
                <a href="/membership" target="_self">Back</a>
                <span>To Pay: £{props.planDone.price}</span>
            </div>

            <Elements stripe={stripePromise}>
                <Stripe plan={props.planDone} closeModal={props.closeModal}/>
            </Elements>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(PayCardModal)

