import React from 'react'
import {Badge} from 'react-bootstrap';
import {Link} from "react-router-dom";

const DarkBadges = (props) => {
    return (
        <Link to={props.link}><Badge bg="dark" className="">{props.badge}</Badge></Link>
    )
}
export default DarkBadges;
