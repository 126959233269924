import React from 'react'
import {Container} from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import UrlResolver from "../../../../service/UrlResolver";

const TestimonialSecton = (props) => {
    if (props.testimonials.length === 0) {
        return <></>;
    }
    return (
        <div className="testimonial py-5">
            <Container>
                <OwlCarousel
                    className='owl-theme'
                    loop={true} dots={true} nav={true} navContainerClass="d-none"
                    margin={10}
                    responsive={{
                        0: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }}
                >
                    {props.testimonials.map(testimonial => {
                        return <div className="testimonial-item" key={testimonial.id}>
                            <div className="d-flex flex-column align-items-center">
                                <h3>What others are saying</h3>
                                <p>{testimonial.text}</p>

                                <img
                                    className=""
                                    src={UrlResolver.generateMediaUrl(testimonial.image)}
                                    alt={testimonial.name}
                                />
                                <span className="name">{testimonial.name}</span>
                                <span>{testimonial.title}</span>
                            </div>
                        </div>
                    })}

                </OwlCarousel>
            </Container>
        </div>
    );
}

export default TestimonialSecton;
