import React from 'react'
import {Form} from "react-bootstrap";

const PaymentInput = (props) => {
    return (
        <Form.Group className="mb-3 paymentInput" controlId="formBasicEmail">
            <Form.Label>{props.label}</Form.Label>
            <Form.Control type={props.type} placeholder={props.placeholder}/>
            {props.err &&
            <Form.Text className="text-muted">
                {props.err}
            </Form.Text>
            }
        </Form.Group>
    );
}

export default PaymentInput;
