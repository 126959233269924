import React from 'react'
import ButtonRound from "../../../components/buttons/ButtonRound";
import {connect} from "react-redux";

const JoinTelos = (props) => {
    return (
        <div className="section laningSectionLight">
            <div className="jinTelos d-flex flex-column align-items-center">
                <h3>What is your Telos?</h3>
                <p> Join the Telos Tribe</p>

                {!props.user.jwt ?
                    <ButtonRound
                        link="/auth/register"
                        name="Get Started"
                    />
                    :
                    <ButtonRound
                        link="/home"
                        name="Get Started"
                    />
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(JoinTelos);

