import React from 'react'
import {ListGroup} from 'react-bootstrap';
import {Link} from "react-router-dom";
import styled from 'styled-components';

const Button = styled.span`
  cursor: pointer;
  background: none;
  min-width: 0;
  padding: 0;
  margin: 0;
  display:flex;
  justify-content: center;
  align-items: center;
`

const PopoverIconsGroup = (props) => {
    console.log(props);
    return (
        <ListGroup horizontal className="iconGroup popoverIcons">
            <Link to={props.link1}>
                {props.active ?
                    <div className="iconRound active">
                        <img src="../../../../assets/img/v2/play.svg" alt="icon"/>
                    </div>
                    :
                    <div className="iconRound">
                        <img src="../../../../assets/img/v2/play.svg" alt="icon"/>
                    </div>
                }
            </Link>
            <Button onClick={props.addToWishlist}>
                {props.inWishlist ?
                    <div className="iconRound active">
                        <img src="../../../../assets/img/tick.svg" alt="icon"/>
                    </div>
                    :
                    <div className="iconRound">
                        <img src="../../../../assets/img/v2/plus.svg" alt="icon"/>
                    </div>
                }
            </Button>
            <Button onClick={props.like}>
                {props.liked ?
                    <div className="iconRound active">
                        <img src="../../../../assets/img/v2/heart-active.svg" alt="icon"/>
                    </div>
                    :
                    <div className="iconRound">
                        <img src="../../../../assets/img/v2/heart.svg" alt="icon"/>
                    </div>
                }
            </Button>
        </ListGroup>
    )
}
export default PopoverIconsGroup;
