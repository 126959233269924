import React, {useEffect, useState} from 'react'
import {Container} from "react-bootstrap";
import NotificationList from './components/NotificationList';
import {Api} from "../../../service/Api";

const Notifications = (props) => {

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        Api.getNotifications().then(response => {
            setNotifications(response);
        })
    }, []);

    return (
        <div className="notifications">
            <Container>
                {notifications.map(notification => {
                    return <NotificationList notification={notification} key={`notification-${notification.id}`}/>
                })}
            </Container>
        </div>
    );
}

export default Notifications;
