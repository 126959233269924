import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import SelectInterest from "./components/SelectInterest";
import ProfileButtons from "../../components/buttons/ProfileButtons";
import {Api} from "../../../service/Api";
import {useHistory, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import customConfirmDialog from "../../components/CustomConfirmDialog";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const MyInterests = ({user, categories: _categories}) => {

    const [categories, setCategories] = useState([]);
    const query = useQuery();
    const history = useHistory();

    const reset = () => {
        setCategories(_categories.filter(c => c.interests).map(c => {
            c.selected = user.interests.indexOf(c.id) > -1;
            return c;
        }))
    }

    useEffect(() => {
        setCategories(_categories.filter(c => c.interests).map(c => {
            c.selected = user.interests.indexOf(c.id) > -1;
            return c;
        }))
    }, [_categories, user.interests]);

    const onChange = (category) => {
        let _categories = categories.map(c => {
            if (c.id === category.id) {
                c.selected = !c.selected;
            }
            return c;
        })
        setCategories(_categories);
    }

    const save = () => {
        Api.saveInterests(categories.filter(category => {
            return category.selected;
        }).map(c => {
            return c.id
        })).then(response => {
            if (query.get("next") === "membership") {
                history.push("/membership")
            } else {
                customConfirmDialog("Success!", "Your changes have been successfully saved", () => {

                }, "Okay")
            }
        }).catch(console.log)
    };


    return (
        <Container className="">
            {query.get("next") === "membership" ?
                <div className="mb-4 ">
                    <h2>Thank you! What are you interested in?</h2>
                </div>
                :
                <div className="w-100 d-flex flex-column justify-content-center align-items-center mb-4 ">
                    <h2>Your Interests</h2>
                </div>
            }
            <SelectInterest categories={categories} onChange={onChange}/>


            <div className="d-flex flex-row justify-content-center mt-3">
                {query.get("next") === "membership" ?
                    <ProfileButtons link={'/membership'}
                                    back={true}
                                    name="Skip"
                    /> :
                    <ProfileButtons onClick={reset}
                                    back={true}
                                    name="Cancel"
                    />
                }
                <div className="me-2"/>
                <ProfileButtons onClick={save}
                                name="Update"
                />
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        categories: state.categories
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCategories: (categories) => dispatch({type: "UPDATE_CATEGORIES", data: categories}),
        updateUser: (user) => dispatch({type: "LOGIN", data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInterests);
