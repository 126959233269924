export default class Helper {
    static shortenString(description: string | undefined | null, characters = 250) {
        if (!description) {
            return;
        }
        return (description.length > characters) ? description.substr(0, characters - 1) + '...' : description;
    }

    static shuffle(array: []) {
        if (!array) {
            return [];
        }
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }
}
