import React from 'react'
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const VideoInfo = (props) => {

    return (
        <div className="videoInfo">
            <ReactMarkdown children={props.detail} rehypePlugins={[rehypeRaw]}/>
            {/* <p>{props.detail}</p> */}
        </div>
    )
}

export default VideoInfo;

