import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import PaymentInput from "../../../inputs/PaymentInput";
import ButtonRound from "../../../buttons/ButtonRound";
import TextButton from "../../../buttons/TextButton";

const AddCardModal = (props) => {
    return (
        <div className="paymentModal">
            <div className="modalHeader">
                <img src={props.icon} alt="png"/>
                {props.title}
            </div>
            <Form>
                <PaymentInput
                    label="Card Number"
                    placeholder="Card Number"
                    type="text"
                />
                <PaymentInput
                    label="Cardholder’s Name"
                    placeholder="Name"
                    type="text"
                />
                <Row className="g-2">
                    <Col md="6">
                        <PaymentInput
                            label="Expiration Date"
                            placeholder="00/00"
                            type="text"
                        />
                    </Col>
                    <Col md="6">
                        <PaymentInput
                            label="CVV"
                            placeholder="000"
                            type="text"
                        />
                    </Col>
                </Row>
                <div className="mt-3"/>
                <ButtonRound
                    name="Save"
                />
                <div className="mt-2"/>
                <TextButton
                    name="Back"
                />
            </Form>
        </div>
    );
}

export default AddCardModal;
