import React from 'react'
import {Nav} from 'react-bootstrap';

const IconButton = (props) => {
    return (
        <Nav.Link href={props.link} className="iconButton for">
            <img src={props.icon} alt="icon"/> {props.name}
        </Nav.Link>
    )
}
export default IconButton;
