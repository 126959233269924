import React, {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap';
import PaymentHistoyrTab from "./billingComponent/PaymentHistoryTab";
import BillingMethodTab from "./billingComponent/BillingMethodTab";

const BillingTabs = (props) => {
    const [key, setKey] = useState('history');

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="history" title="Payment History">
                <div className="d-flex justify-content-center">
                    <PaymentHistoyrTab/>
                </div>
            </Tab>
            <Tab eventKey="billing" title="Billing Method">
                <div className="d-flex justify-content-center">
                    <BillingMethodTab/>
                </div>
            </Tab>
        </Tabs>
    );
}

export default BillingTabs;
