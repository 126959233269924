import React, {useEffect, useState} from 'react'
import {Link, useParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import SmallVideoThumb from "../../components/sections/smallVideoThumb/SmallVideoThumb";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import {Api} from '../../../service/Api';
import UrlResolver from "../../../service/UrlResolver";

const VideoCategory = (props) => {

    const [catvideos, setCatVideos] = useState(null);

    let {id} = useParams();

    useEffect(() => {
        Api.getCategory(id).then(response => {
            setCatVideos(response)
        })

    }, [id]);

    if (!catvideos) {
        return <></>
    }
    return (
        <div className="blogs categories-all">
            <Container>
                <SectionHeading
                    title={catvideos.title}
                />
                {catvideos.categories && catvideos.categories.length > 0 &&
                <Row>
                    {catvideos.categories.map(category => {
                        return <Col md={3} >
                            <Link  to={`/videoCategory/${category.id}`}>
                                <SmallVideoThumb
                                    thumb={UrlResolver.generateBlogMediaUrl(category.image.url)}
                                    title={category.title}
                                />
                            </Link>
                        </Col>
                    })}
                </Row>
                }
                <Row>
                    {catvideos.videos.map(video => {
                        return <Col md={3} sm={6} xs={6}>
                            <SmallVideoThumb
                                video={video}
                            />
                        </Col>
                    })}
                </Row>
            </Container>
        </div>
    );
}
export default VideoCategory;
