import React from 'react'
import {Col, Container, Row} from "react-bootstrap";

const TelosLive = (props) => {
    return (
        <div className="section laningSectionDark">
            <Container>
                <Row className="align-items-center">
                    <Col xl={7}>
                        <div className="telosHeading landingSectionHeading">
                            <h2>
                                Telos Live
                            </h2>
                            
                            <div>
                                <p>
                                Live conversations with leading experts around the world  <br/>Live meditation sessions  <br/>Q & A sessions 
                                </p>
                                <p></p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={5}
                         className="d-flex justify-content-center"
                    >
                        <img
                            src="../../../../assets/landing/webinar.png"
                            alt="live"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default TelosLive;
