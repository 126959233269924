import React from 'react'
import {Nav} from 'react-bootstrap';
import Loader from 'react-loader-spinner';

const ProfileButtons = (props) => {

    if (props.loading) {
        return <Nav.Link href={'#'} className={props.back ? "profileButtonWithoutBack" : "profileButton"}>
            <Loader
                type="ThreeDots"
                color="#ffffff"
                height={20}
                width={20}
            />
        </Nav.Link>
    }

    return (
        <Nav.Link onClick={props.onClick} href={props.link}
                  className={props.back ? "profileButtonWithoutBack" : "profileButton"}>
            {props.name}
        </Nav.Link>
    )
}
export default ProfileButtons;
