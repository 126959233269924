import React from 'react'
import {Col, Row} from "react-bootstrap";
import UrlResolver from "../../../../service/UrlResolver";
import Moment from 'moment';

const NotificationList = ({notification}) => {

    return (
        <a href={notification.link}>
            <Row>
                <Col md={12}>
                    <div className="notifications-list">

                        <div className="notification-left">
                         <span>{Moment(notification.created_at).format('MMMM DD,YYYY')}</span>
                            <h3>{notification.title}</h3>
                            <p>{notification.description}</p>
                        </div>
                        <div className="notification-right">
                            <img src={UrlResolver.generateMediaUrl(notification.image)} alt="Icon"/>
                        </div>
                    </div>
                </Col>
            </Row>
        </a>

    );
}

export default NotificationList;
