import React from 'react'
import {Form} from "react-bootstrap";

const DefaultInput = (props) => {
    return (
        <Form.Group className="mb-3 defaultInput" controlId={`formBasic${props.name}`}>
            {props.label &&
            <Form.Label>{props.label ? props.label : 'Email address'}</Form.Label>
            }
            <Form.Control
                name={props.name}
                onBlur={props.onBlur}
                readOnly={props.readOnly}
                onChange={props.onChange}
                type={props.type}
                value={props.value}
                placeholder={props.placeholder}
                className={props.error && "input-error"}
            />
            {props.error &&
            <Form.Text className="text-danger">
                {props.error}
            </Form.Text>
            }
            {props.hint &&
            <Form.Text className="text-muted">
                {props.hint}
            </Form.Text>
            }
        </Form.Group>
    );
}

export default DefaultInput;
