import React from 'react'
import {Col, Container, Row} from "react-bootstrap";

const BookClub = (props) => {
    return (
        <div className="section laningSectionLight">
            <Container>
                <Row className="align-items-center">
                    <Col md={12} xl={7}
                         className="d-flex  pc-book"
                    >
                        <img
                            src="../../../../assets/landing/book-club.jpeg"
                            alt="live"
                        />
                    </Col>
                    <Col md={12} xl={5}>
                        <div className="bookClubHeading landingSectionHeading">
                            <h2>
                                Book Club
                            </h2>
                            <ul className="d-flex">
                                <li>Self-Development</li>
                                <li>Productivity</li>
                                <li>Wealth</li>
                            </ul>
                            <div>
                                <p>
                                    Elite summaries of some of the best personal development books
                                </p>
                                <p>Key concepts unravelled within minutes</p>
                                <p>Upgrade Yourself Daily</p>
                            </div>
                        </div>
                    </Col>

                    <Col md={12} xl={6}
                         className="d-flex justify-content-center phone-book"
                    >
                        <img
                            src="../../../../assets/landing/book-club.jpeg"
                            alt="live"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BookClub;
