import React from 'react'
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {Api} from "../../../../service/Api"; // ES2015

class HeaderSearch extends React.Component {
    typeAhead = React.createRef();
    myRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {searchInput: '', addClass: false, isLoading: false, options: []};
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    onKeyDown = (e) => {
        // Check whether the 'enter' key was pressed, and also make sure that
        // no menu items are highlighted.
        if (e.keyCode === 13) {
            window.location.href = ('/searchResults?query=' + this.typeAhead.current.state.text);
        }
    };

    handleClickOutside = e => {
        if (!this.myRef.current.contains(e.target)) {
            this.setState({addClass: false});
        }
    };

    toggle() {
        this.setState({addClass: true});
    }


    render() {
        return (
            <div
                ref={this.myRef}
                className="searchBox"
                onClick={this.toggle.bind(this)}
            >
                {this.state.addClass ?
                    <div className="searchInput">
                        <AsyncTypeahead
                            ref={this.typeAhead}
                            autoFocus={true}
                            placeholder={'Search anything and enter'}
                            isLoading={this.state.isLoading}
                            labelKey={option => `${option.title}`}
                            onChange={(option) => {
                                window.location.href = `/videoDetail/${option[0].id}`
                            }}
                            onKeyDown={this.onKeyDown}
                            onSearch={(query) => {
                                //this.setState({isLoading: true});
                            }}
                            options={this.state.options}
                        />
                        <img className="icon18" src="../../../../assets/img/search-white.png" alt="search"/>
                    </div>
                    :
                    <div className="searchIcon">
                        <img className="icon18" src={[this.props.icon]} alt="svg"/>
                    </div>
                }
            </div>
        );
    }
}

export default HeaderSearch;
