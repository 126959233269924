import React from 'react'
import {Form} from "react-bootstrap";

const PaymentSmallInput = (props) => {
    return (
        <Form.Group className="mb-3 paymentInput smallInput" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email"/>
            {props.err &&
            <Form.Text className="text-muted">
                We'll never share your email with anyone else.
            </Form.Text>
            }
        </Form.Group>
    );
}

export default PaymentSmallInput;
