import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import VideoPopover from "./components/VideoPopover";
import UrlResolver from "../../../../service/UrlResolver";

const VidepSlide = (props) => {

    if (!props.videos) {
        return <></>
    }
    return (
        <OwlCarousel
            className='owl-theme owl-stage-outer'
            loop={false} dots={false} nav={true} navContainerClass="d-none"
            margin={10}
            stagePadding={50}
            responsive={{
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                900: {
                    items: 2
                },
                1000: {
                    items: 4
                }
            }}
        >
            {props.videos.map((video) => {
                let percent = 0;
                if (video.video) {
                    percent = video.percent;
                    video = video.video;
                }
                //console.log(video)

                return <VideoPopover
                    title={video.title}
                    seek={video}
                    percent={percent}
                    showProgress={props.showProgress}
                    thumb={UrlResolver.generateMediaUrl(video.thumbnail)}
                />
            })}
        </OwlCarousel>
    )
}

export default VidepSlide;
