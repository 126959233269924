import React, {useEffect, useState} from 'react'
import {Container, Tab, Tabs} from 'react-bootstrap';
import EditProfile from "./EditProfile";
import BillingTabs from "./BillingTabs";
import {Link} from "react-router-dom";
import MyInterests from "../../myInterests/MyInterests";
import {Api} from "../../../../service/Api";

const ProfileTabs = (props) => {
    const [key, setKey] = useState('home');
    const [currentSubscription, setCurrentSubscription] = useState({});

    useEffect(() => {
        Api.currentSubscription().then(response => {
            setCurrentSubscription(response)
        }).catch(e => {
            setCurrentSubscription(null)
        })
    }, []);
    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="home" title="Profile Settings">
                <Container>
                    <div className="profileCard">
                        <EditProfile/>


                    </div>
                </Container>
            </Tab>
            <Tab eventKey="profile" title="Interests">
                <Container>
                    <div className="profileCard my-interests">
                        <MyInterests/>
                    </div>
                </Container>
            </Tab>
            <Tab eventKey="contact" title="Billing Method">
                <Container>
                    <div className="profileCard billingCard">
                        {currentSubscription && currentSubscription.membership && currentSubscription.membership.type === 'monthly' &&
                        <Link to="/membership" className="align-self-end upgradeButton for-desktop">
                            Upgrade to yearly
                        </Link>
                        }

                        <BillingTabs/>
                    </div>
                </Container>
            </Tab>
        </Tabs>
    );
}

export default ProfileTabs;
