import React, {useState} from "react";
import {Button, Nav, Navbar, Offcanvas} from "react-bootstrap";
import UrlResolver from "../../../../service/UrlResolver";

function MobileHeader(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    return (
        <div className="mobileHeader">
            <Navbar bg="dark" expand="lg">
                <div className="mob-head-left">
                    <Button variant="primary" onClick={toggleShow} className="me-2 menuIcon">
                        <img height="70" src="../../../../assets/img/menu.png" alt="logo"/>
                    </Button>
                    <Navbar.Brand href="#home">
                        <img height="50" src={props.logo} alt="logo"/>
                    </Navbar.Brand>
                </div>
                <div className="mobileSearch">
                    <form action={'/searchResults'}>
                        <input type="text" placeholder="Search" name={'query'} autoComplete="off"/>
                        <button><img className="icon18" src="../../../../assets/img/search-white.png" alt="search"/>
                        </button>
                    </form>
                </div>
            </Navbar>
            <Offcanvas className="sideMenu" show={show} onHide={handleClose} {...props}>
                <SideMenu
                    categories={props.categories}
                    user={props.user}
                />
            </Offcanvas>
        </div>
    );
}

const initials = (name) => {
    if (!name) {
        return ""
    }
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    return initials;
}

const SideMenu = (props) => {


    const handleLogout = (event) => {
        localStorage.clear();
        window.location.assign("/")
    }

    return (
        <>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    {props.user && props.user.image ?
                        <img className="icon46" src={props.user ? UrlResolver.generateMediaUrl(props.user.image) : ''}
                             alt="icon"/> :
                        <span className="icon46 text">{props.user && (initials(props.user.name))}</span>
                    }
                    <span className="ms-2">{props.user ? props.user.name : ''}</span>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="sidebarLink">
                <ul>
                    <SideMenuLink name="Accounts Settings" link={'/profile'}/>
                    <SideMenuLink name="Saved/My List" link={'/watchlist'}/>
                    <SideMenuLink name="Sign Out" onClick={handleLogout}/>
                </ul>
                <ul>
                    {props.categories.map(category => {
                        return <SideMenuLink name={category.title} link={`/videoCategory/${category.id}`}/>
                    })}
                </ul>
            </Offcanvas.Body>
        </>
    )
}

const SideMenuLink = (props) => {
    return (
        <Nav.Link href={props.link} onClick={props.onClick}>{props.name}</Nav.Link>

    )
}

export default MobileHeader;
