import React, {useState} from 'react';
import DefaultInput from "../../components/inputs/DefaultInput";
import DefaultButton from "../../components/buttons/DefaultButton";
import {Api} from '../../../service/Api';
import {Formik} from 'formik';

const CreatePassword = () => {
    const [failed, setFailed] = useState({});

    let url_string = window.location.href;
    let url = new URL(url_string);
    let codeing = url.searchParams.get("code");


    return (
        <div className="p-5">
            <div className="authTitle">
                <h1>Create New Password</h1>
            </div>
            <Formik
                initialValues={{passwordConfirmation: '', password: '', code: ''}}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors.password = 'Enter Password';
                    }
                    if (!values.passwordConfirmation) {
                        errors.passwordConfirmation = 'Enter Confirm Password';
                    }

                    if (values.password !== values.passwordConfirmation) {

                        errors.match = 'Passwords not matched';
                    }
                    return errors;
                }}
                onSubmit={(values, {setSubmitting}) => {

                    Api.changePassword({
                        code: codeing,
                        password: values.password,
                        passwordConfirmation: values.passwordConfirmation,
                    }).then(response => {
                        alert('Reset password');
                        window.location.assign("/auth");
                    }).catch((e) => {
                        setFailed({error: e.response.data.message[0].messages[0].message});
                    })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <DefaultInput error={errors.password}
                                      placeholder="Enter new password"
                                      type="password"
                                      name="password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                        />
                        <DefaultInput error={errors.passwordConfirmation}
                                      placeholder="Confirm new password"
                                      type="password"
                                      name="passwordConfirmation"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.passwordConfirmation}
                        />
                        {errors.match && <div className="donthave-acc mt-3"><h4>{errors.match}</h4></div>}
                        {failed.error && <div className="donthave-acc mt-3"><h4>{failed.error}</h4></div>}
                        <DefaultButton type={'submit'} disabled={isSubmitting} name={'Save'}/>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default CreatePassword;
