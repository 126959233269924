import React from 'react'
import {Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import ReactPlayer from "react-player";
import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import DefaultInput from '../../../components/inputs/DefaultInput';

const BannerSecton = (props) => {
    const history = useHistory();
    return (
        <div className="bannerSection">
            <div className="bannerContent">
                <Container>
                    <Row className="align-items-center">
                        <Col xl={5} xs={12}>
                            <h2>
                                Telos is the “Supreme end of man’s endeavour”
                            </h2>
                            <p>
                            Aristotle referred to it as “The full potential or inherent purpose of a person”</p>
                            <p> It is the ultimate lifes purpose!
                            </p>
                            <p>
                            It’s a journey towards an ever more fulfilled and best possible version of you.
                            </p>
                            

                            <Formik
                                initialValues={{email: ''}}
                                validateOnChange={false}
                                validateOnBlur={false}
                                validate={values => {
                                    const errors = {};

                                    if (!values.email) {
                                        errors.email = 'Required';
                                    } else if (
                                        !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i.test(values.email)
                                    ) {
                                        errors.email = 'Invalid email address';
                                    }
                                    return errors;
                                }}
                                onSubmit={(values, {setSubmitting}) => {
                                    localStorage.setItem('remail', values.email);
                                    history.push('/auth/register');
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      /* and other goodies */
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                        <span className='form-heading-new mt-5'>What is your Telos?</span>
                                        <InputGroup className="mt-2">
                                        
                                            <DefaultInput
                                                type="email"
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter your email address"
                                                value={values.email}
                                            />
                                            <Button className="mb-3 btn-warning" type={'submit'} id="basic-addon2"
                                                    isabled={isSubmitting}>
                                                Get Started
                                            </Button>
                                        </InputGroup>
                                        { errors.email &&
                                        <Form.Text className="text-danger">
                                            {errors.email}
                                        </Form.Text>
                                        }
                                    </form>
                                )}
                            </Formik>

                            {/* <InputGroup className="mt-5">
                                <FormControl
                                    placeholder="Enter your email address"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                                <Button className="btn-warning" id="basic-addon2">
                                    Get Started
                                </Button>
                            </InputGroup> */}
                        </Col>
                        <Col xl={7} xs={12}>
                            <div className="banner-video">
                                <iframe src="https://player.vimeo.com/video/647302845?h=9e8faad5dc&autoplay=1"
                                        width="640"
                                        height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default BannerSecton;
