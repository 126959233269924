import React from 'react'
import {Route, Switch} from 'react-router-dom';
import Home from "../../main/home/Home";
import Header from "../header/Header";
import Elements from "../../main/elements/Elements";
import MyInterests from "../../main/myInterests/MyInterests";
import VideoDetail from "../../main/videoDetail/VideoDetail";
import BookDetail from "../../main/bookDetail/BookDetail";
import Profile from "../../main/profile/Profile";
import Membership from "../../main/membership/Membership";
import Blogs from "../../main/blogs/Blogs";
import VideoCategory from "../../main/categories/VideoCategory";
import VideoTags from "../../main/categories/VideoTag";
import BookTags from "../../main/categories/BookTag";
import VideoCategories from "../../main/categories/VideoCategories";
import BookCategory from "../../main/categories/BookCategory";
import SearchPage from "../../main/searchPage/SearchPage";
import BlogDetail from "../../main/blogs/BlogDetail";
import privacyPolicy from "../../main/pages/privacyPolicy";
import Webinar from "../../main/webinar/Webinar";
import PaymentModal from "../modals/paymentModal/PaymentModal";
import WatchList from "../../main/categories/Watchlist";
import Recommended from "../../main/categories/Recommended";
import ContinueWatching from "../../main/categories/ContinueWatching";
import Notifications from '../../main/Notifications/Notifications';
import NotFoundPage from "../../main/notFound/NotFoundPage";
import WebinarDetail from "../../main/webinar/WebinarDetail";

const MainRoute = ({user}) => {
    return (
        <Route render={props => (
            <>
                <Header/>
                <div className="main">
                    <Switch>
                        <Route exact path="/home" component={Home}/>
                        <Route exact path="/watchlist" component={WatchList}/>
                        <Route exact path="/recommended" component={Recommended}/>
                        <Route exact path="/continue" component={ContinueWatching}/> 
                        <Route exact path="/videoDetail/:id" render={(props)=>{
                            return <VideoDetail key={props.match.params.id} {...props} />
                        }}/>
                        <Route exact path="/profile" component={Profile}/>
                        <Route exact path="/membership" component={Membership}/>
                        <Route exact path="/membership/pay" component={PaymentModal}/>
                        {/*<Route exact path="/landing" component={Landing}/>*/}

                        <Route exact path="/myInterests" component={MyInterests}/>
                        <Route exact path="/blogs/:id" component={Blogs}/>
                        <Route exact path="/blogDetail/:id" component={BlogDetail}/>
                        <Route exact path="/privacyPolicy/" component={privacyPolicy}/>
                        <Route exact path="/videoCategory" component={VideoCategories}/>
                        <Route exact path="/videoCategory/:id" component={VideoCategory}/>
                        <Route exact path="/videoTags/:name" component={VideoTags}/>
                        <Route exact path="/booktags/:name" component={BookTags}/>
                        <Route exact path="/bookCategory/:id" component={BookCategory}/>
                        <Route exact path="/bookDetail/:id" component={BookDetail}/>
                        <Route exact path="/webinar/:name" component={Webinar}/>
                        <Route exact path="/webinar-detail/:id" component={WebinarDetail}/>
                        <Route exact path="/SearchResults" component={SearchPage}/>

                        <Route exact path="/elements" component={Elements}/>
                        <Route exact path="/notifications" component={Notifications}/>
                        <Route component={NotFoundPage}/>

                    </Switch>
                </div>
                {/*<Footer/>*/}
            </>
        )}
        />
    )
};
export default MainRoute;

