import React, {useEffect, useState} from 'react'
import BannerSection from "./components/BannerSection";
import InterestSection from "./components/InterestSection";
import TestimonialSection from "./components/TestimonialSection";
import TelosLive from "./components/TelosLive";
import BookClub from "./components/BookClub";
import WatchAnywhere from "./components/WatchAnywhere";
import FAQ from "./components/FAQ";
import JoinTelos from "./components/JoinTelos";
import {Api} from "../../../service/Api";
import {connect} from "react-redux";
import Home from "../home/Home";
import {useHistory} from "react-router-dom";


const Landing = (props) => {

    const [categories, setCategories] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    useEffect(() => {
        Api.getCategories().then(response => {
            setCategories(response.filter(c => c.show_in_landing_page))
        })
        Api.getFaqs().then(response => {
            setFaqs(response)
        })
        Api.getTestimonials().then(response => {
            setTestimonials(response)
        })
    }, []);

    let history = useHistory();
    useEffect(() => {
        if (props.user.user) {
            history.push('/home');
        }
    }, [props.user, history]);


    if (props.user.user) {
        return <Home/>;
    }

    return (
        <>
            <BannerSection/>
            <InterestSection categories={categories}/>
            <TelosLive/>
            <BookClub/>
            <WatchAnywhere/>
            <TestimonialSection testimonials={testimonials}/>
            <FAQ faqs={faqs}/>
            <JoinTelos/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing)
