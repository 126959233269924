import React, {useEffect, useState} from 'react'
import {Link, useParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import SmallVideoThumb from "../../components/sections/smallVideoThumb/SmallVideoThumb";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import {Api} from '../../../service/Api';
import {connect} from "react-redux";

const Recommended = (props) => {

    const [catvideos, setCatVideos] = useState(null);

    useEffect(() => {
        Api.getRecommendedVideos(props.user.user).then(response => {
            setCatVideos(response)
        })

    }, []);

    if (!catvideos) {
        return <></>
    } else {
        console.log(catvideos);
    }
    return (
        <div className="blogs">
            <Container>
                <SectionHeading
                    title={"Continue Watching"}
                />
                <Row>
                    {catvideos.map(video => {
                        return <Col md={3}>
                            <Link to={`/videoDetail/${video.id}`}>
                                <SmallVideoThumb
                                    showProgress={true}
                                    video={video}
                                />
                            </Link>
                        </Col>
                    })}
                </Row>
            </Container>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommended)
