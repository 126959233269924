import React from 'react'
import ProfileTabs from "./components/ProfileTabs";

const Profile = (props) => {
    return (
        <div className="profileScreen">
            <ProfileTabs/>
        </div>
    );
}

export default Profile;
