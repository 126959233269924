import {confirmAlert} from 'react-confirm-alert'; // Import

const getIcon = (iconType) => {
    switch (iconType) {
        case 'success':
            return <img src={'../../../assets/img/v2/tick-with-circle.svg'} alt="Success"
                        style={{width: "50px", height: "50px", marginRight: "10px"}}/>
        case 'error':
            return <img src={'../../../assets/img/v2/error.svg'} alt="Error"
                        style={{width: "50px", height: "50px", marginRight: "10px"}}/> 
        default:
            return null;
    }
}

const customConfirmDialog = (title, message, onYes, yesLabel = 'yes', onNo, noLabel = 'No', iconType = 'success') => {
    const buttons = [];

    if (onYes) {
        buttons.push(
            {
                label: yesLabel,
                onClick: onYes,
                className: 'yes',
            }
        )
    }
    if (onNo) {

        buttons.push(
            {
                label: noLabel,
                onClick: onNo,
                className: 'no',
            }
        )
    }

    console.log(buttons)
    confirmAlert({
        title,
        message,
        buttons,
        customUI: ({onClose}) => {
            return <div className={'react-confirm-alert-body'}>
                <div className="box">
                    {getIcon(iconType)}
                    <span className={`heading ${iconType}`}>{title}</span>
                </div>
                {message}
                <div className={'buttons'}>
                    {buttons.length > 0 && <button onClick={() => {
                        onClose();
                        buttons[0].onClick();
                    }} className={buttons[0].className}>{buttons[0].label}</button>}
                    {buttons.length > 1 &&
                    <button onClick={() => {
                        onClose();
                        buttons[1].onClick();
                    }} className={buttons[1].className}>{buttons[1].label}</button>}
                </div>
            </div>
        }
    });
}
export default customConfirmDialog;
