import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from "react-bootstrap";
import BlogHorizontalThumb from "../../components/sections/blogHorizontalThumb/BlogHorizontalThumb";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import SmallVideoThumb from "../../components/sections/smallVideoThumb/SmallVideoThumb";
import {Link, useLocation} from "react-router-dom";
import {Api} from "../../../service/Api";
import UrlResolver from "../../../service/UrlResolver";
import Helper from "../../../service/Helper";
import ButtonSmRound from "../../components/buttons/ButtonSmRound";
import DarkBadges from "../../components/badges/DarkBadges";
import Moment from "moment";
import BlogVerticalThumb from '../../components/sections/blogVerticalThumb/BlogVerticalThumb';
import SmallBookThumb from '../../components/sections/smallBookThumb/SmallBookThumb';

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SearchPage = (props) => {

    const query = useQuery();
    const [results, setResults] = useState({videos: [], blogs: [], webinars: [], books: [], videoscategories: [], blogcategories: [], bookcategories: [], blogtags: [], booktags: [] , videostags: [], webinarstags: []});
    const [loading, setLoading] = useState(true);

    const search = () => {
        let q = query.get("query");
         
        Promise.allSettled([
            Api.searchVideos(q),
            Api.searchBlogs(q),
            Api.searchBooks(q),
            Api.searchWebinars(q),
            Api.searchCategories(q),
            Api.searchBlogsCategories(q),
            Api.searchBooksCategories(q),
            // Api.searchBlogsTags(q),
            // Api.searchBooksTags(q),
            // Api.searchVideosTags(q),
            // Api.searchWebinarsTags(q),
        ]).then(searchResults => {
            
            setLoading(false)
            setResults({
                videos: searchResults[0].value,
                blogs: searchResults[1].value,
                books: searchResults[2].value,
                webinars: searchResults[3].value,
                videoscategories: searchResults[4].value,
                blogcategories: searchResults[5].value,
                bookcategories: searchResults[6].value,
                // blogtags: searchResults[7].value,
                // booktags: searchResults[8].value,
                // videostags: searchResults[9].value,
                // webinarstags: searchResults[10].value,
            });
        })
    }

    useEffect(() => {
        search();
    }, [query]);

    if (results) {
        console.log(results.bookcategories);
        console.log(results.blogcategories);
    }


    return (
        <div className="blogs">
            <Container>
                {results.videos.length > 0 &&
                <SectionHeading
                    title={`Video result for ‘${query.get("query")}’`}
                />
                }
                <Row>
                    {results.videos.map(video => {
                        return <Col md={3}>
                            <Link to={`/videoDetail/${video.id}`}>
                                <SmallVideoThumb
                                    video={video}
                                />
                            </Link>
                        </Col>
                    })}

                </Row>

                {results.videoscategories.length > 0 &&
                <SectionHeading
                    title={`Video Categories result for ‘${query.get("query")}’`}
                />
                }
                <Row>
                    {results.videoscategories.map(category => {
                        return <Col md={3}>
                        <Link  to={`/videoCategory/${category.id}`}>
                                <SmallVideoThumb
                                    thumb={UrlResolver.generateBlogMediaUrl(category.image.url)}
                                    title={category.title}
                                />
                            </Link>
                        </Col>
                    })}

                </Row>

               
               {results.blogs.length > 0 &&
                <SectionHeading
                    title={`Blog result for ‘${query.get("query")}’`}
                />
                }
                <Row>
                    {results.blogs.map(blog => {
                        return <Col md={6}>
                            <BlogVerticalThumb
                            thumb={UrlResolver.generateBlogMediaUrl(blog.thumbnail.url)}
                            category={blog.blog_category.name}
                            date={Moment(blog.date).format('MMMM DD,YYYY')}
                            title={blog.title}
                            detail={Helper.shortenString(blog.description, 70)}
                            />
                            {/* <BlogHorizontalThumb
                                id={blog.id}
                                thumb={UrlResolver.generateMediaUrl(blog.thumbnail)}
                                category={blog.blog_category ? blog.blog_category.name : ''}
                                date={blog.date}
                                title={blog.title}
                                detail={Helper.shortenString(blog.description)}
                            /> */}
                        </Col>
                    })}
                </Row>

                {results.blogcategories.length > 0 &&
                <SectionHeading
                    title={`Blog Categories result for ‘${query.get("query")}’`}
                />
                }
                <Row>
                    {results.blogcategories.map(blog => {
                        return <>
                        {blog.blogs.map(blog => {
                        return <Col md={3}>

                            <BlogHorizontalThumb
                                id={blog.id}
                                thumb={UrlResolver.generateBlogMediaUrl(blog.thumbnail.url)}
                                category={blog.blog_category.name}
                                date={Moment(blog.date).format('MMMM DD,YYYY')}
                                title={blog.title}
                                detail={Helper.shortenString(blog.description, 150)}
                            />
                            </Col>
                        })}
                       </>
                    })}
                     </Row>

                {results.webinars.length > 0 &&
                <SectionHeading
                    title={`Webinar result for ‘${query.get("query")}’`}
                />
                }
                <Row>
                    {results.webinars.map((webinar) => {
                        return <WebinerCol
                            col="4"
                            id={webinar.id}
                            thumb={UrlResolver.generateBlogMediaUrl(webinar.thumbnail.url)}
                            overlayTime={Moment(webinar.time).format('HH:mm A, dddd')}
                            overlayDate={Moment(webinar.time).format('DD MMMM YYYY')}
                            title={webinar.title}
                            webTags={webinar.Tags}
                            detail={`${webinar.details.substring(0, 150)}...`}
                        />
                    })}
                </Row>

                {results.books.length > 0 &&
                <SectionHeading
                    title={`Book result for ‘${query.get("query")}’`}
                />
                }
                <Row>
                    {results.books.map(book => {
                        return <Col md={4}>
                            <div className='item bookCategoryItem'>
                                <img src={UrlResolver.generateMediaUrl(book.cover)} alt="thumb"/>
                                <div className="bookDetail">
                                    <p>
                                        {book.title}
                                    </p>
                                    <span className="author-name">by {book.authors.map(author => {
                                        return author.name
                                    }).join(', ')}</span>
                                    <ButtonSmRound
                                        name="Read Elite Summary"
                                        link={`/bookDetail/${book.id}`}
                                    />
                                </div>
                            </div>

                        </Col>
                    })}
                </Row>

                {results.bookcategories.length > 0 &&
                <SectionHeading
                    title={`Book Categories result for ‘${query.get("query")}’`}
                />
                }
                <Row>
                    {results.bookcategories.map(book => {
                        return <>
                        {book.books.map(books => {
                        return <Col md={3} >
                        <SmallBookThumb
                                key={`book-${books.id}`}
                                thumb={UrlResolver.generateMediaUrl(books.cover)}
                                title={books.title}
                                link={`/bookDetail/${books.id}`}
                                authors=''
                                tags={books.tags}
                            />
                        </Col>
                        })}
                       </>
                    })}

                </Row>
                {!loading && results.blogs.length === 0 && results.videos.length === 0 && results.webinars.length === 0 && results.books.length === 0 && results.videoscategories.length === 0 && results.bookcategories.length === 0 && results.blogcategories.length === 0 &&
                <NoSearchFound type={'Search'} query={query.get('query')}/>}
            </Container>
        </div>
    );
}

const NoSearchFound = (props) => {
    return (
        <div>
            <SectionHeading
                title={`${props.type} result for ‘${props.query}’`}
            />

            <div className="no-search">
                <img src="../../../../assets/img/empty-search.png" alt="empty"/>
                <h3>Your search for “{props.query}” did not find any matches.</h3>
            </div>
        </div>
    )
}

const WebinerCol = (props) => {
    return (
        <Col md={props.col}>
            <div className="webinerCol">
                <div className='item smallThumb'>
                    <img src={props.thumb} alt="thumb"/>
                    <div className="thumbOverlay">
                        <div className="videoTitle">
                            <span>{props.overlayTime}</span>
                            <span>{props.overlayDate}</span>
                        </div>
                    </div>
                </div>
                <div className="mt-1">
                    <div className="mt-3 slideBadge">
                        {props.webTags.map((tag) => {
                            return <DarkBadges badge={tag.title} link={`/webinar/${tag.title}`}/>
                        })}
                    </div>
                    <h3><Link to={`/webinar-detail/${props.id}`}>{props.title}</Link></h3>
                    <p>{props.detail}</p>
                </div>
            </div>
        </Col>
    )
}

export default SearchPage;
