import React from 'react'
import {NavDropdown} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {Api} from "../../../service/Api";
import UrlResolver from "../../../service/UrlResolver";

const NotificationDropdown = ({notifications, ...props}) => {

    const onOpen = () => {
        Api.updateNotificationOpenTimestamp(Date.now()).then(console.log).catch(console.log);
    }

    const navDropdownTitle = (<img className="icon24" src={props.notification} alt="icon"/>);
    return (
        <NavDropdown title={navDropdownTitle} onClick={onOpen} className="notificationDrop" id="basic-nav-dropdown">
            {notifications.map(n => {
                return <DropItem
                    link={n.link}
                    thumb={UrlResolver.generateMediaUrl(n.image)}
                    name={n.title}
                />
            })}
            <NavDropdown.Item href={'/notifications'} className="view-button-notification">View All</NavDropdown.Item>
        </NavDropdown>
    )
}

const DropItem = (props) => {
    return (
        <NavDropdown.Item href={props.link} className="" >
            <img src={props.thumb} alt="thumb"/>
            {props.name}
        </NavDropdown.Item>
    )
}
export default NotificationDropdown;
