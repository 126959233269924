import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DefaultInput from "../../../components/inputs/DefaultInput";
import ProfileButtons from "../../../components/buttons/ProfileButtons";
import { connect } from "react-redux";
import { Api } from "../../../../service/Api";
import UrlResolver from "../../../../service/UrlResolver";
import customConfirmDialog from "../../../components/CustomConfirmDialog";
import { Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import Moment from 'moment';

const EditProfile = (props) => {

    const [input, setInput] = useState({ name: '' });
    const [imageData, setImageData] = useState(null);
    const [image, setImage] = useState(null);
    const [changePassword, setChangePassword] = useState(false);

    const reset = () => {
        setInput({ name: props.user.name });
        setImageData(null);
        setImage(null);
    }
    useEffect(() => {
        if (!props.user) {
            return;
        }
        setInput({ name: props.user.name });
    }, [props.user]);

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);

    }
    const save = () => {
        let data = { ...input };
        Api.updateProfile(data).then(user => {
            props.updateUser(user);
            customConfirmDialog("Success", "Your changes have been successfully saved", () => {

            }, "Okay")
            if (image) {
                let formData = new FormData();
                formData.append("files", image);
                formData.append("refId", props.user.id);
                formData.append("ref", "user");
                formData.append("source", "users-permissions");
                formData.append("field", "image");
                Api.uploadFile(formData).then(response => {
                    setImage(null)

                }).catch(console.log)

            }
        })

    }

    const fileInputRef = useRef(null);


    const onFileInputChange = (event) => {
        const { files } = event.target;

        // Assuming only image
        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setImage(file);

        reader.onloadend = function (e) {
            setImageData(reader.result);
        };
    };

    const selectFile = () => {
        fileInputRef.current.click();
    }

    if (changePassword) {
        return <ChangePassword onCancel={() => {
            setChangePassword(false)
        }
        } />
    }

    return (
        <>
            <div>
                <div className="d-flex justify-content-center mb-4">
                    <h3>Account Settings</h3>
                </div>
                <Row className="align-items-center">
                    <Col md={6}>
                        <div className="d-flex justify-content-center align-items-center">

                            {props.user.image || imageData ?
                                <div className="userImage" onClick={selectFile} style={{ cursor: "pointer" }}>
                                    <img src={imageData || UrlResolver.generateMediaUrl(props.user.image)} alt="user" />
                                </div>
                                :
                                <div className="addUserImage" onClick={selectFile} style={{ cursor: "pointer" }}>
                                    <img src="../../../../assets/img/camera.svg" alt="camera" />
                                </div>
                            }
                        </div>
                        <input
                            style={{ display: "none" }}
                            onChange={onFileInputChange}
                            ref={fileInputRef}
                            type="file"
                            className="hidden"
                        />
                    </Col>
                    <Col md={6}>
                        <div className="editProfileForm">
                            <DefaultInput
                                value={input.name}
                                label="Name"
                                name={'name'}
                                onChange={handleChange}
                                placeholder="Name"
                            />
                            <DefaultInput
                                value={props.user.email}
                                readOnly={true}
                                label="Email"
                                placeholder="Email"
                            />
                            <Link to={'#'} onClick={() => {
                                setChangePassword(true)
                            }}>Change password</Link>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="d-flex flex-row justify-content-center mt-5">
                <ProfileButtons
                    onClick={save}
                    name="Update"
                />
                <div className="me-2" />
                <ProfileButtons
                    back={true}
                    onClick={reset}
                    name="Cancel"
                />
            </div>
            <div className="text-center text-white" style={{ fontSize: "13px", marginTop: "10px" }}>Member
                since {Moment(props.user.created_at).format('DD MMMM YYYY HH:mm A')}</div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (user) => dispatch({ type: "UPDATE_USER", data: user })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
