import React from 'react'
import {Accordion} from "react-bootstrap";
import UrlResolver from '../../../service/UrlResolver';

const Elements = (props) => {

    if (!props.lesson) {
        return <></>
    } else {
        console.log(props.lesson);
    }

    if (props.show_without_sections) {
        return props.lesson.map((section, index) => {
            return <div className={'accordion-body no-sections'}>{section.lessons.map(less => {
                return <div className="d-flex flex-row align-items-center only-lessons mb-3" style={{cursor: 'pointer'}}
                            onClick={() => props.onhandle(less)}>
                            {less.thumbnail !== null &&
                              <img src={UrlResolver.generateBlogMediaUrl(less.thumbnail.url)} alt="thumb"/>
                            }
                    <p  className="photo-overlay">{less.title}</p>
                </div>
            })}
            </div>
        })
    }

    return (
        <Accordion defaultActiveKey="0">
            {props.lesson.map((section, index) => {
                return <Accordion.Item eventKey={index}>
                    <Accordion.Header>{section.title}</Accordion.Header>
                    {section.lessons.map(less => {
                        return <Accordion.Body>
                            <div className="d-flex flex-row align-items-center for-mobile-only" style={{cursor: 'pointer'}}
                                 onClick={() => props.onhandle(less)}>
                                <img src={UrlResolver.generateBlogMediaUrl(less.thumbnail.url)} alt="thumb"/>
                                <p className="ms-2">{less.title}</p>
                            </div>
                        </Accordion.Body>
                    })}
                </Accordion.Item>
            })}
            {/* <Accordion.Item eventKey="1">
                <Accordion.Header>Week 2</Accordion.Header>
                <Accordion.Body>
                    <div className="d-flex flex-row align-items-center">
                        <img src={props.thumb} alt="thumb"/>
                        <p className="ms-2">{props.title}</p>
                    </div>
                </Accordion.Body>
            </Accordion.Item> */}
        </Accordion>
    );
}

export default Elements;
