import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import CommentCard from "../../../components/cards/commentCard/CommentCard";
import CommentInputCard from "../../../components/cards/commentInputCard/CommentInputCard";
import VideoInfo from "./VideoInfo";
import AccordinFrame from "../../../components/accordinFrame/AccordinFrame";
import { connect } from "react-redux";

const VideoDetailTabs = ({ refreshComments, ...props }) => {
    const [key, setKey] = useState('home');

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 videoTabs"
        >
            <Tab eventKey="accordinTab" title="Episodes">
                <AccordinFrame
                    lesson={props.lesson}
                    show_without_sections={props.show_without_sections}
                    onhandle={props.onhandle}
                />
            </Tab>
            {props.inform &&
                <Tab eventKey="home" title="Information">
                    <VideoInfo
                        user="David"
                        detail={props.inform}
                    />
                </Tab>
            }
            {/* <Tab eventKey="profile" title="Discussion">
                <CommentInputCard id={props.video.id} refresh={refreshComments}/>
                <CommentCard video={props.video} refresh={refreshComments}/>
            </Tab> */}
        </Tabs>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(VideoDetailTabs);
