import React, {useEffect, useState} from 'react'
import MainSlider from "../../components/sliders/mainSlider/MainSlider";
import {Api} from "../../../service/Api";
import {Container} from "react-bootstrap";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import VideoSlide from "../../components/sliders/videoSlide/VideoSlide";
import Helper from "../../../service/Helper";
import {connect} from "react-redux";
import BookSlide from "../../components/sliders/bookSlide/BookSlide";
import BlogSlide from "../../components/sliders/blogSlide/BlogSlide";

const FeaturedVideos = ()=>{
    const [fvideos, setFvideos] = useState([]);
    useEffect(()=>{
        Api.getFeaturedVideos().then(response => {
            setFvideos(response);
        })
    },[])
    console.log("Rendering featured");
    return <MainSlider fvideos={fvideos}/>
};
const Books = ()=>{
    const [books, setBooks] = useState([]);
    useEffect(()=>{
        Api.getBooks().then(response => {
            setBooks(response);
        })
    },[])
    return  <Container>
        <SectionHeading
            title="Book Club"
            rightText="See All" link={'/bookCategory/all'}
            seeAll={true}
        />
        <div className="slideContainer">
            <BookSlide books={books}/>
        </div>
    </Container>
};

const Blogs = ()=>{
    const [blogs, setBlogs] = useState([]);
    useEffect(()=>{
        Api.getBlogs().then(response => {
            let descBlog = response.sort((a, b) => b.id - a.id);
            setBlogs(descBlog);
        })
    },[])
    return  <Container>
        <SectionHeading
            title="Blogs"
            rightText="See All" link={'/blogs/all'}
            seeAll={true}
        />
        <div className="slideContainer">
            <BlogSlide blogs={blogs}/>
        </div>
    </Container>
};
const ContinueWatching = ()=>{
    const [continueWatching, setContinueWatching] = useState([]);
    useEffect(()=>{
        Api.getContinueWatching().then(response => {
            // console.log('ramesh by me');
            // console.log(response);
            setContinueWatching(response);
        })
    },[])
    return <>{continueWatching.length > 0 && <> <Container>
            <SectionHeading
                title="Continue watching"
                rightText="See All"
                seeAll={true}
                link="/continue"
            />
        
                <div className="slideContainer">
                    <VideoSlide showProgress={true} videos={continueWatching}
                                seek={true}/>
                </div>
            </Container>
        </>
    }</>
};
const Recommended = ({user})=>{
    const [recommendedVideos, setRecommendedVideos] = useState([]);
    useEffect(()=>{
        Api.getRecommendedVideos(user.user).then(response => {
            setRecommendedVideos(response);
        })
    },[user])
    return <>{recommendedVideos.length > 0 &&
    <Container>
        <SectionHeading
            title="Recommended for you"
            rightText="See All"
            seeAll={true}
            link="/recommended"
        />
        <div className="slideContainer">
            <VideoSlide videos={Helper.shuffle(recommendedVideos)}/>
        </div>
    </Container>
    }</>
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        categories: state.categories
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

const RecommendedConnected = connect(mapStateToProps, mapDispatchToProps)(Recommended)
const FeaturedMemo =  React.memo(FeaturedVideos);
export {
    FeaturedMemo,
    ContinueWatching,
    Books,
    Blogs,
    RecommendedConnected
}
