import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import ButtonSmRound from "../../components/buttons/ButtonSmRound";
import {Api} from '../../../service/Api';
import UrlResolver from "../../../service/UrlResolver";
import ReactMarkdown from 'react-markdown';
import DarkBadges from '../../components/badges/DarkBadges';
import rehypeRaw from 'rehype-raw';
import Moment from 'moment';

const BookDetail = (props) => {


    const [bdetail, setBdetail] = useState(null);
    const [catbooks, setCatbooks] = useState([]);

    let {id} = useParams();

    useEffect(() => {
        Api.getBookdetail(id).then(response => {
            setBdetail(response);

            if (response) {
                let catId = response.book_category.id;

                Api.getBookbycategory(catId).then(response => {

                    setCatbooks(response);
                })
            }
        })

    }, []);

    if (!bdetail) {
        return <></>
    }

    if (!catbooks) {
        return <></>
    }

    return (


        <div className="blogs blogThumb">
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="mb-3">
                            <div className='item bookCategoryItem detail-page'>
                                <img src={UrlResolver.generateMediaUrl(bdetail.cover)} alt="thumb"/>
                                <div className="bookDetail">
                                    <p className="main-title">
                                        {bdetail.title}
                                    </p>
                                    <span className="author-name">by {bdetail.authors.map(author => {
                                        return author.name
                                    }).join(', ')}</span>
                                    <div className="video-tags">
                                        {bdetail.tags.map((tag) => {
                                            return <DarkBadges badge={tag.title} link={`/booktags/${tag.title}`}/>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <h5 className="me-2"><Link className="detail-link"
                                                                   to={`/bookCategory/${bdetail.book_category.id}`}> {bdetail.book_category.title} </Link>
                                        </h5>
                                        <img className="show-line" src="../../../../assets/img/show-line.png"
                                             alt="icon"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="desc-title">{bdetail.title}</h3>
                        <div className="detailed-description">
                            <ReactMarkdown children={bdetail.description} rehypePlugins={[rehypeRaw]} transformImageUri={uri =>
        uri.startsWith("http") ? uri : `https://backend.telos.itmatic.io${uri}`
      }/>
                        </div>

                    </Col>

                    <Col md={4}>
                        <span className="detail-date">{Moment(bdetail.published_at).format('MMMM DD,YYYY')}</span>
                        <div className="d-flex flex-wrap">
                            {/* <div className="video-tags detailed-tags">
                                {bdetail.tags.map((tag) => {
                                    return <DarkBadges badge={tag.title} link={`/booktags/${tag.title}`}/>
                                })}
                            </div> */}
                            {/* <ButtonSmRound mr={true} name="# Social"/>
                            <ButtonSmRound mr={true} name="# Physical Health"/>
                            <ButtonSmRound mr={true} name="# Financial Health"/>
                            <ButtonSmRound mr={true} name="# Emotional Health"/> */}
                        </div>
                        <div className="relatedBlogs mt-3">
                            {catbooks.filter(b => b.id !== bdetail.id).map((book) => {
                                return <div className='item bookCategoryItem related'>
                                    <img src={UrlResolver.generateMediaUrl(book.cover)} alt="thumb"/>
                                    <div className="bookDetail">
                                        <p className="related-title">
                                            {book.title}
                                        </p>
                                        <span className="author-name">by {book.authors.map(author => {
                                            return author.name
                                        }).join(', ')}</span>
                                        <ButtonSmRound
                                            name="Read Elite Summary"
                                            link={`/bookDetail/${book.id}`}
                                        />
                                        <div className="video-tags">
                                            {book.tags.map((tag) => {
                                                return <DarkBadges badge={tag.title} link={`/booktags/${tag.title}`}/>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            })}
                            {/* <div className="d-flex flex-row align-items-center">
                                <img src="../../../../assets/thumbs/video-detail/yoga.jpg" alt="thumb"/>
                                <p className="ms-2">The Revolutionary Functionality of the Doona Car Seat & Stroller</p>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <img src="../../../../assets/thumbs/video-detail/yoga.jpg" alt="thumb"/>
                                <p className="ms-2">The Revolutionary Functionality of the Doona Car Seat & Stroller</p>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <img src="../../../../assets/thumbs/video-detail/yoga.jpg" alt="thumb"/>
                                <p className="ms-2">The Revolutionary Functionality of the Doona Car Seat & Stroller</p>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BookDetail;
