import React from "react";
import {Link} from "react-router-dom";

const BlogHorizontalThumb = (props) => {
    return (
        <Link to={`/blogDetail/${props.id}`}>
            <div className='blogThumb horizontalBlog blog-last-box'>
                <img src={props.thumb} alt="thumb"/>
                <div className="mt-3 blog-right-side">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <h5 className="me-2">{props.category}</h5>
                            <img className="show-line" src="../../../../assets/img/show-line.png" alt="icon"/>
                        </div>
                        <span>{props.date}</span>
                    </div>
                    <h3>{props.title}</h3>
                    <p>{props.detail}</p>
                </div>
            </div>
        </Link>
    )
}
export default BlogHorizontalThumb;
