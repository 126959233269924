import React, {useCallback, useState} from 'react'
import {Form} from "react-bootstrap";
import CommentButton from "../../buttons/CommentButton";
import {Api} from "../../../../service/Api";

const CommentInputCard = ({id, refresh}) => {


    const [comment, setComment] = useState('');

    const submit = useCallback(
        () => {
            if (!comment) {
                return;
            }
            setComment('');
            Api.postComment(id, comment).then(() => {
                refresh();
            }).catch(e => {

            })
        },
        [id, comment],
    );

    return (
        <div className="d-flex ">
            <User user="../../../../assets/img/user.png"/>
            <div className="d-flex flex-column align-items-end flex-grow-1 discussion-space ps-lg-4">
                <CommentInput value={comment} onChange={e => {
                    setComment(e.target.value);
                }}/>
                <div className="mt-2 d-flex">
                    <CommentButton name="Cancel" onClick={() => {
                        setComment('')
                    }}/>
                    <CommentButton back={true} onClick={submit} name="Comment"/>
                </div>
            </div>
        </div>
    )
}

const User = (props) => {
    return (
        <img src={props.user} className="avatar" alt="user"/>
    )
}

const CommentInput = ({value, onChange}) => {
    return (
        <Form.Control type="text" value={value} onChange={onChange} placeholder="Type Your Comment Here.."/>
    )
}
export default CommentInputCard;
