import React from 'react'
import {Nav} from 'react-bootstrap';

const DefaultButton = (props) => {
    if (props.type === 'submit') {
        return <button type={'submit'} className="defaultButton">
            {props.name}
        </button>
    }
    return (
        <Nav.Link href={props.link} className="defaultButton">
            {props.name}
        </Nav.Link>
    )
}
export default DefaultButton;
