import React from "react";

const BlogVerticalThumb = (props) => {
    return (
        <div className='blogThumb verticalBlog blog-last-box'>
            <img src={props.thumb} alt="thumb"/>
            <div className="blog-right-side">
                <div className="blog-titles-mobile">
                    <h3>{props.title}</h3>

                </div>
                <div className=" blog-list-mobile">
                    <div className="d-flex align-items-center ">
                        <h5 className="me-2">{props.category}</h5>
                        <img className="show-line" src="../../../../assets/img/show-line.png" alt="icon"/>
                    </div>
                    <span>{props.date}</span>
                </div>
                <div className="blog-titles-desktop">
                    <h3>{props.title}</h3>
                    <p>{props.detail}</p>
                </div>
            </div>
        </div>
    )
}
export default BlogVerticalThumb;
