import React from 'react'
import ButtonRound from "../../../buttons/ButtonRound";

const SuccessModal = (props) => {
    return (
        <div className="statusModal successModal">
            <div className="modalHeader">
                <div className="modalIcon">
                    <img src="../../../../assets/img/check.png" alt="icon"/>
                </div>
                {props.title}
            </div>
            <div className="modalBody">
                <p>{props.detail}</p>
            </div>
            <div className="modalFooter">
                <ButtonRound
                    name={props.name}
                />
            </div>
        </div>
    );
}

export default SuccessModal;
