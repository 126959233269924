import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import SmallVideoThumb from "../../components/sections/smallVideoThumb/SmallVideoThumb";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import { Api } from '../../../service/Api';

const WatchList = (props) => {

    const [catvideos, setCatVideos] = useState(null);

    useEffect(() => {
        Api.getWatchlist().then(response => {
            setCatVideos(response)
        })

    }, []);

    if (!catvideos) {
        return <></>
    } else {
        // console.log(catvideos);
    }
    return (
        <div className="blogs">
            <Container>
                <SectionHeading
                    title={"Saved / My List"}
                />
                {catvideos.length > 0 ?
                    <Row>
                        {catvideos.map(video => {

                            return <Col md={3}>
                                <Link to={`/videoDetail/${video.id}`}>
                                    <SmallVideoThumb
                                        video={video}
                                    />
                                </Link>
                            </Col>
                        })}
                    </Row>
                    :
                    <div className="no-search">
                        <h3>You have not added anything yet!</h3>
                    </div>

                }

            </Container>
        </div>
    );
}
export default WatchList;
