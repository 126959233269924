import React from 'react'
import {Nav} from 'react-bootstrap';

const ButtonSm = (props) => {
    return (
        <Nav.Link href={props.link} className="smallButton new-login">
            {props.name}
        </Nav.Link>
    )
}
export default ButtonSm;
