import React from 'react'
import {Nav} from 'react-bootstrap';

const IconBorderButton = (props) => {
    return (
        <Nav.Link onClick={props.onClick} href={props.link} className="iconBorderButton">
            <img src={props.icon} alt="icon"/> {props.name}
        </Nav.Link>
    )
}
export default IconBorderButton;
