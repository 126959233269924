import React, {useEffect, useState} from 'react'
import {NavDropdown} from 'react-bootstrap';
import {Api} from "../../../service/Api";

const DefaultDropdown = (props) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        Api.getBrowseItems().then(response => {
            setItems(response);
        })
    }, []);

    return (
        <NavDropdown title="Browse" className="defaultDrop me-2" id="basic-nav-dropdown">
            {items.map(item => {
                return <DropItem name={item.title} link={item.url} key={item.id}/>;
            })}

        </NavDropdown>
    )
}

const DropItem = (props) => {
    return (
        <NavDropdown.Item href={props.link}>{props.name}</NavDropdown.Item>
    )
}
export default DefaultDropdown;
