import React, {useEffect, useState} from 'react'
import MainSlider from "../../components/sliders/mainSlider/MainSlider";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import {Container} from "react-bootstrap";
import VideoSlide from "../../components/sliders/videoSlide/VideoSlide";
import BookSlide from "../../components/sliders/bookSlide/BookSlide";
import BlogSlide from "../../components/sliders/blogSlide/BlogSlide";
import {connect} from "react-redux";
import {Api} from '../../../service/Api';
import CateogrySlide from '../../components/sliders/categorySlide/CategorySlide';
import Helper from "../../../service/Helper";
import ClipLoader from "react-spinners/ClipLoader";
import {Books, Blogs, ContinueWatching, FeaturedMemo, FeaturedVideos, RecommendedConnected} from "./components";

const Home = (props) => {
    const [categories, setCategories] = useState({h:[],o:[],i:[]});

    useEffect(() => {

        const{user} = props;
        console.log(Object.keys(user));

        if(Object.keys(user).length > 0){
          console.log("logged in");
        } else {
            window.location.href = ('/');
        }

       // console.log("Home useeffect called");
        Api.getCategories().then(response => {
            
            const h = response.filter(cat => {
                if(cat.parent){
                    return false;
                }
            
                // if (cat.videos.length !== 0) {
                //    return props.user.user.interests.indexOf(cat.id) > -1 || !cat.interests;
                // }

                if (cat.videos.length !== 0) {
                   return true;
                }


            });

            const o = response.filter(cat => {
                
                if (cat.videos.length !== 0) {
                    if(cat.parent){
                        return false;
                    }
                 return props.user.user.interests.indexOf(cat.id) === -1 && cat.interests;
                } else if(cat.videos.length === 0) {
                    if(cat.categories.length !== 0) {
                       return props.user.user.interests.indexOf(cat.id) === -1 && cat.interests;
                   }
                }
            });

            const i = response.filter(cat => {
               
                if (cat.videos.length === 0) {
                    if(cat.categories.length !== 0) {
                    return props.user.user.interests.indexOf(cat.id) > -1 || !cat.interests;
                    }
                }
            });


            setCategories({h,o,i});
        })
    }, [props.user.user]);

   //console.log(categories);

    return (
        <>
            <FeaturedMemo/>
            <div className="homePage equal-height">
                {/*Continue watching*/}
                <ContinueWatching/>

                {/*Recommended for you*/}
                <RecommendedConnected/>

                {categories.h.map(category => {
                    return <Container>
                        <SectionHeading
                            title={category.title}
                            rightText="See All"
                            seeAll={true}
                            link={`/videoCategory/${category.id}`}
                        />
                        <div className="slideContainer">
                            <VideoSlide videos={category.videos}/>
                        </div>
                    </Container>
                })}

                {categories.i.map(category => {
                    return <Container>
                        <SectionHeading
                            title={category.title}
                            rightText="See All"
                            seeAll={true}
                            link={`/videoCategory/${category.id}`}
                        />
                        <div className="slideContainer">
                          <CateogrySlide categories={category.categories}/>
                        </div>
                    </Container>
                })}

                 {/* {categories.o.length !== 0 &&

                <Container>
                    <SectionHeading
                        title="Other Categories"
                        rightText="See All"
                        seeAll={true}
                        link="/videoCategory"
                    />
                    <div className="slideContainer">
                        <CateogrySlide categories={categories.o}/>
                    </div>
                </Container>
                } */}

                <Books/>

                <Blogs/>

                {/*Book Club*/}

            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (user) => dispatch({type: 'LOGIN', data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home)
