import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Col, Container, NavDropdown, Row } from "react-bootstrap";
import { Api } from '../../../service/Api';
import UrlResolver from '../../../service/UrlResolver';
import SectionHeading from "../../components/sectionHeading/SectionHeading";

import Moment from 'moment';
import DarkBadges from '../../components/badges/DarkBadges';

const Webinar = (props) => {

    Moment.locale('en');

    const [webinars, setWebinars] = useState([]);

    const [uwebinars, setUwebinars] = useState([]);

    const [pwebinars, setPwebinars] = useState([]);

    const [tagwebis, setTagWebis] = useState(null);

    let { name } = useParams();

    useEffect(() => {

        Api.getWebinars().then(response => {

            let newArr = [];
            response.map((item) => {
                item.Tags.map((item2) => {
                    if (newArr.indexOf(item2.title) === -1) { newArr.push(item2.title) }
                })
            })

            newArr.unshift("all");

            setTagWebis(newArr);


            if (name !== 'all') {
                setWebinars(response.filter(tvideo => {
                    return tvideo.Tags.length > 0 && tvideo.Tags.find(o => o.title === name);

                }));

                setUwebinars(response.filter(uweb => {
                    let webinar_date = new Date(uweb.time);
                    let current_date = new Date();

                    if (webinar_date > current_date && uweb.Tags.length > 0 && uweb.Tags.find(o => o.title === name)) {
                        return uweb;
                    }

                }));

                setPwebinars(response.filter(pweb => {
                    let pwebinar_date = new Date(pweb.time);
                    let pcurrent_date = new Date();

                    if (pwebinar_date < pcurrent_date && pweb.Tags.length > 0 && pweb.Tags.find(o => o.title === name)) {
                        return pweb;
                    }
                }));
            } else {
                setWebinars(response);

                setUwebinars(response.filter(uweb => {
                    let webinar_date = new Date(uweb.time);
                    let current_date = new Date();

                    if (webinar_date > current_date) {
                        return uweb;
                    }

                }));

                setPwebinars(response.filter(pweb => {
                    let pwebinar_date = new Date(pweb.time);
                    let pcurrent_date = new Date();

                    if (pwebinar_date < pcurrent_date) {
                        return pweb;
                    }
                }));

            }

        })

    }, [name]);

    if (!webinars) {
        return <></>
    } else {
       // console.log(webinars);
        //console.log(pwebinars.sort((a,b) =>  new Date(b.time) - new Date(a.time) ));
    }

    if (!tagwebis) {
        return <></>
    }

    return (
        <div className="blogs">
            <Container>

                <SectionHeading
                    title="Webinar"
                    paddingRight={'0'}
                    rightChild={<>
                        <NavDropdown title={name} className="defaultDrop me-2" id="basic-nav-dropdown">
                            {tagwebis.map(webs => {
                                return <DropItem link={`/webinar/${webs}`} name={webs}
                                    key={`category-${webs}`} />
                            })}
                        </NavDropdown>
                    </>
                    }
                />

                <SectionHeading
                    title="Upcoming Conversations"
                />
                <Row>
                    {uwebinars.slice(0, 2).map((webinar) => {
                        return <WebinerCol
                            col="6"
                            id={webinar.id}
                            thumb={UrlResolver.generateBlogMediaUrl(webinar.thumbnail.url)}
                            overlayTime={Moment(webinar.time).format('HH:mm A, dddd')}
                            overlayDate={Moment(webinar.time).format('DD MMMM YYYY')}
                            title={webinar.title}
                            webTags={webinar.Tags}
                            detail={`${webinar.details.substring(0, 150)}...`}
                        />
                    })}
                    {/* <WebinerCol
                        col="6"
                        thumb="../../../../assets/thumbs/video/video5.jpg"
                        overlayTime="11:00 AM, Monday"
                        overlayDate="20th October 2021"
                        title="Winter Car Seat Safety Tips"
                        detail="The unseen of spending three years at Pixelgrade Ways Data Science Can Grow  Your
                         Business Faster Building A Modern Reading Platform Like Kindle How Tech Continues To Solve
                         Problems"
                    /> */}
                </Row>

                <SectionHeading
                    title="Past Conversations"
                />
                <Row>
                
                    {pwebinars.sort((a,b) =>  new Date(b.time) - new Date(a.time) ).map((webinar) => {

                        return <WebinerCol
                            col="4"
                            id={webinar.id}
                            thumb={UrlResolver.generateBlogMediaUrl(webinar.thumbnail.url)}
                            overlayTime={Moment(webinar.time).format('HH:mm A, dddd')}
                            overlayDate={Moment(webinar.time).format('DD MMMM YYYY')}
                            title={webinar.title}
                            webTags={webinar.Tags}
                            detail={`${webinar.details.substring(0, 150)}...`}
                        />
                    })}
                    {/* <WebinerCol
                        col="4"
                        thumb="../../../../assets/thumbs/video/video5.jpg"
                        overlayTime="11:00 AM, Monday"
                        overlayDate="20th October 2021"
                        title="Winter Car Seat Safety Tips"
                        detail="The unseen of spending three years at Pixelgrade Ways Data Science Can Grow  Your
                         Business Faster Building A Modern Reading Platform Like Kindle How Tech Continues To Solve
                         Problems"
                    />
                    <WebinerCol
                        col="4"
                        thumb="../../../../assets/thumbs/video/video5.jpg"
                        overlayTime="11:00 AM, Monday"
                        overlayDate="20th October 2021"
                        title="Winter Car Seat Safety Tips"
                        detail="The unseen of spending three years at Pixelgrade Ways Data Science Can Grow  Your
                         Business Faster Building A Modern Reading Platform Like Kindle How Tech Continues To Solve
                         Problems"
                    /> */}
                </Row>
            </Container>
        </div>
    );
}

const WebinerCol = (props) => {
    return (
        <Col md={props.col}>
            <div className="webinerCol">
                <div className='item smallThumb'>
                    <Link to={`/webinar-detail/${props.id}`} className='cursor'>
                        <img src={props.thumb} alt="thumb" />

                        <div className="thumbOverlay">
                            <div className="videoTitle">
                                <span>{props.overlayTime}</span>
                                <span>{props.overlayDate}</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="mt-1">
                    <div className="mt-3 slideBadge">
                        {props.webTags.map((tag) => {
                            return <DarkBadges badge={tag.title} link={`/webinar/${tag.title}`} />
                        })}
                    </div>
                    <h3><Link to={`/webinar-detail/${props.id}`}>{props.title}</Link></h3>
                    <Link to={`/webinar-detail/${props.id}`}><p>{props.detail}</p></Link>
                </div>
            </div>
        </Col>
    )
}

const DropItem = (props) => {
    return (
        <NavDropdown.Item href={props.link}>{props.name}</NavDropdown.Item>
    )
}

export default Webinar;
