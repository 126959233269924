import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import SmallBookThumb from "../../components/sections/smallBookThumb/SmallBookThumb";
import {Api} from "../../../service/Api";
import UrlResolver from "../../../service/UrlResolver";

const BookTag = (props) => {

    const [tagbooks, setTagBooks] = useState(null);

    let {name} = useParams();

    useEffect(() => {
        Api.getTagBooks().then(response => {

            setTagBooks(response.filter(tbook => {
                return tbook.tags.length > 0 && tbook.tags.find(o => o.title === name);

            }));
        })

    }, [name]);

    if (!tagbooks) {
        return <></>
    } else {
        console.log(tagbooks);
    }
    return (
        <div className="blogs">
            <Container>
                <SectionHeading
                    title={name}
                />
                <Row>
                    {tagbooks.map(book => {
                        return <Col md={3}>
                            <SmallBookThumb
                                thumb={UrlResolver.generateMediaUrl(book.cover)}
                                title={book.title}
                                link={`/bookDetail/${book.id}`}
                                authors={book.authors}
                                tags={book.tags}
                            />
                        </Col>
                    })}

                </Row>
            </Container>
        </div>
    );
}
export default BookTag;
