import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import DefaultInput from "../../../components/inputs/DefaultInput";
import ProfileButtons from "../../../components/buttons/ProfileButtons";
import {connect} from "react-redux";
import {Api} from "../../../../service/Api";
import customConfirmDialog from "../../../components/CustomConfirmDialog";

const EditProfile = (props) => {

    const [failed, setFailed] = useState({});

    const [input, setInput] = useState({password: '', newPassword: ''});

    const reset = () => {
        setInput({password: '', newPassword: ''})
        props.onCancel();
    }

    const handleChange = (e) => {
        let i = {...input};
        i[e.target.name] = e.target.value;
        setInput(i);
    }
    const save = () => {
        console.log(props.user);

        Api.newPassword({
            identifier: props.user.email,
            password: input.password,
            newPassword: input.newPassword,
            confirmPassword: input.newPassword,
        }).then(response => {
            customConfirmDialog("Success", "Changed password", () => {

            }, "Okay")

        }).catch((e) => {
            setFailed({error: e.response.data.message[0].messages[0].message});
        })
    }

    return (
        <>
            <div>
                <div className="d-flex justify-content-center mb-4">
                    <h3>Change Password</h3>
                </div>
                <Row className="align-items-center">

                    <Col md={12} className={'d-flex justify-content-center'}>
                        <div className="editProfileForm flex-grow-1">
                            <DefaultInput
                                value={input.password}
                                label="Current Password"
                                name={'password'}
                                type={'password'}
                                onChange={handleChange}
                                placeholder="Current Password"
                            />
                            <DefaultInput
                                value={props.newPassword}
                                onChange={handleChange}
                                label="New Password"
                                type={'password'}
                                name={'newPassword'}
                                placeholder="New Password"
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="d-flex flex-row justify-content-center mt-5">
                {failed.error && <div className="donthave-acc mt-3"><h4>{failed.error}</h4></div>}
                <ProfileButtons
                    back={true}
                    name="Cancel"
                    onClick={reset}
                />
                <div className="me-2"/>
                <ProfileButtons
                    onClick={save}
                    name="Update"
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (user) => dispatch({type: "UPDATE_USER", data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
