import React from 'react'
import DarkBadges from '../../badges/DarkBadges';
import IconsGroup from "./components/IconsGroup";


const VideoDetailCard = (props) => {
    return (
        <div className="videDetail">
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-column flex-grow-1">
                    <div className="title-tags-flex">
                        <h2>{props.title}</h2>
                        
                    </div>
                    <div className="video-dated-formate" style={{marginBottom:0}}>
                        <span>{props.view} Views |</span>
                        <span > {props.time}</span>
                    </div>
                    <div className="video-tags ">
                            {props.videoTags.map((tag) => {
                                return <DarkBadges badge={tag.title} link={`/videoTags/${tag.title}`}/>
                            })}
                        </div>
                    <p>{props.detail}</p>
                </div>
                <div className="ps-4 ss">
                    <IconsGroup id={props.id} totalLikes={props.totalLikes} notes={props.notes}/>
                </div>
            </div>
        </div>
    )
}

export default VideoDetailCard;
