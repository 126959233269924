import React from 'react'
import {Nav} from 'react-bootstrap';

const ButtonSmRound = (props) => {
    return (
        <Nav.Link href={props.link} className={["smallRoundButton", props.mr && "mr-2"]}>
            {props.name}
        </Nav.Link>
    )
}
export default ButtonSmRound;
