import React from 'react'
import SmallVideoThumb from "../../../components/sections/smallVideoThumb/SmallVideoThumb";
import UrlResolver from "../../../../service/UrlResolver";


const SelectInterest = ({categories, onChange}) => {
    return (
        <div className={'col-md-12'}>
            <ul className="inter-boxses">
                {categories.map(category => {
                    return <li className={`interests ${category.selected ? 'active' : ''}`} onClick={() => {
                        onChange(category)
                    }}>
                        <div className="interests-box">
                            <label className="form-check-label" htmlFor="inlineCheckbox1">
                                <SmallVideoThumb
                                    thumb={UrlResolver.generateMediaUrl(category.image)}
                                    // title={category.title}
                                />
                            </label>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    )
}

export default SelectInterest;
