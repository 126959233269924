import React from 'react'
import {Link} from "react-router-dom";

const SectionHeading = ({paddingRight = '20px', ...props}) => {
    return (
        <div className="sectionHeading d-flex flex-row justify-content-between align-items-center"
             style={{paddingRight: paddingRight}}>
            <h2 className='category-page-title'><Link to={props.link}>{props.title}</Link></h2>
            {props.seeAll &&
            <Link to={props.link}>
                {props.rightText}
                <img
                    className="icon16 m-lg-1"
                    src="../../../../assets/img/right-arrow.png"
                    alt="right arrow"
                />
            </Link>
            }
            {props.rightChild && props.rightChild}
        </div>
    )
}
export default SectionHeading;
