import React, {useEffect, useState} from 'react';
import {Card, Container, Modal} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {Api} from '../../../service/Api';
import PayCardModal from '../../components/modals/paymentModal/components/PayCardModal';


const Membership = (props) => {
    const [selectedPlan, setSelectedPlan] = useState({price: "", plan: "", cuserID: ""});

    const [addCard, setAddCard] = useState(false);
    const [payCard, setPayCard] = useState(false);

    const hideAddCard = () => setAddCard(true);

    const hidePayCard = () => setPayCard(true);
    const history = useHistory();

    const membershippay = (price, plan) => {
        let cuserID = props.user.id;

        if (price === 0) {
            history.push("/home")
            return;
        }

        const dataPlan = {
            price,
            plan,
            cuserID,
        }

        setPayCard(true);

        setSelectedPlan(dataPlan);

    };

    const [ymembership, setYmemberships] = useState([]);
    const [mmembership, setMmemberships] = useState([]);
    const [basicMembership, setBasicMembership] = useState([]);

    const [type, setType] = useState('monthly');


    useEffect(() => {

        Api.getMemberships().then(response => {

            setYmemberships(response.filter(ymember => {
                return ymember.type === 'yearly';
            }));

            setMmemberships(response.filter(ymember => {
                return ymember.type === 'monthly';
            }));

            setBasicMembership(response.filter(ymember => {
                return ymember.type === 'basic';
            }));


        })

    }, []);

    if (!ymembership) {
        return <></>
    }

    if (!mmembership) {
        return <></>
    }

    return (
        <>
            <div className="membership">
                <Container>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" onChange={() => {
                            setType(type === 'monthly' ? 'yearly' : 'monthly')
                        }} checked={type === 'yearly'} value={type === 'yearly'} className="custom-control-input d-none"
                               id="customSwitch1"/>
                        <label className="custom-control-label switchContainer" htmlFor="customSwitch1">
                            <span className="monthly">Monthly</span>
                            <div className="switchButton">
                                <div className="switchCircle"/>
                            </div>
                            <span className="annual">Annual</span>
                        </label>
                    </div>

                    <div className=" subscription-design">
                        <MonthCard type={type === 'monthly' ? mmembership : ymembership} handleclick={membershippay}
                                   user={props.user}/>
                        <BasicCard type={basicMembership} handleclick={membershippay} user={props.user}/>
                    </div>
                </Container>
            </div>

            <Modal show={payCard} onHide={hidePayCard} animation={false} centered>
                <PayCardModal
                    icon="../../../../assets/img/credit-card.png"
                    title="Credit Card"
                    detail="Your payment has been made for Premiuim membership. Please enjoy!"
                    name="Done"
                    closeModal={hidePayCard}
                    planDone={selectedPlan}
                />
            </Modal>
        </>
    )
}

const MonthCard = (props) => {
    if (!props.type) {
        return <></>
    }
    return (
        <>
            {props.type.map(membership => {

                return <Card className="card-deck">
                    <div className="memberCardLogo">
                        <img src="../../../../assets/img/logo.png" alt="logo"/>
                    </div>
                    <div className="popular-banner">
                        <img src="../../../../assets/img/popular-banner.svg" alt="logo"/>
                    </div>
                    <div className="annualCardHeading">
                        <h2>{membership.Name}</h2>
                        <span>{membership.fee_words}
                            <span>{membership.fee_words_per}</span>
                        </span>
                    </div>
                    <ul>
                        {membership.Features.map(feature => {
                            return <li>{feature.FeatureText}</li>
                        })}
                        {/* <li> Personal development plans</li>
                <li> Download resources</li>
                <li> Comments enabled</li>
                <li> Add to watch later</li>
                <li> Audio only</li> */}
                    </ul>
                    <div className="memberCardButton">
                        {!props.user ? <Link to={'/auth/register'}>Register</Link> :
                            <Link to="#" onClick={() => props.handleclick(membership.fee, membership.id)}>Get
                                Started</Link>}
                    </div>
                </Card>
            })}

        </>
    )
}

const BasicCard = (props) => {
    if (!props.type) {
        return <></>
    }

    return (
        <>
            {props.type.map(membership => {

                return <Card className="card-deck annualCard">
                    <div className="memberCardLogo">
                        <img src="../../../../assets/img/logo.png" alt="logo"/>
                    </div>
                    <div className="annualCardHeading">
                        <h2>{membership.Name}</h2>
                        <span>{membership.fee_words}
                            <span>{membership.free_words_per}</span>
                        </span>
                    </div>
                    <ul>
                        {membership.Features.map(feature => {
                            return <li>{feature.FeatureText}</li>
                        })}
                    </ul>
                    <div className="memberCardButton">
                        {!props.user ? <Link to={'/auth/register'}>Register</Link> :
                            <Link to="#" onClick={() => props.handleclick(membership.fee, membership.id)}>Get
                                Started</Link>}

                    </div>
                </Card>
            })}

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

export default connect(mapStateToProps)(Membership);

