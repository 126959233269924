import React from 'react'
import {Col, Container, Row} from "react-bootstrap";

const WatchAnywhere = (props) => {
    return (
        <div className="section laningSectionDark">
            <Container>
                <Row className="align-items-center">
                    <Col xl={7}>
                        <div className="telosHeading watch landingSectionHeading d-flex justify-content-center">
                            <h2>
                                Watch anywhere, anytime & on  any device
                            </h2>
                            {/* <div className="d-flex mt-4">
                                <ul className="d-flex flex-column">
                                    <li><p>Spiritual Freedom</p></li>
                                    <li><p>Mental Peace</p></li>
                                    <li><p>Emotional Harmony</p></li>
                                </ul>
                                <ul className="d-flex flex-column">
                                    <li><p>Spiritual Freedom</p></li>
                                    <li><p>Mental Peace</p></li>
                                    <li><p>Emotional Harmony</p></li>
                                </ul>
                            </div> */}
                        </div>
                    </Col>
                    <Col xl={5}
                         className="d-flex justify-content-start"
                    >
                        <img
                            src="../../../../assets/landing/watch-anywhere.png"
                            alt="live"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WatchAnywhere;
