import React from 'react'
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    max-width: 80vw;
    margin: 20px;
  }
`

const NotFoundPage = () => {
    return <Container>
        <img src={'../../../assets/img/v2/404.svg'}/>
    </Container>
}

export default NotFoundPage;
