import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ButtonSmRound from "../../buttons/ButtonSmRound";
import UrlResolver from "../../../../service/UrlResolver";

const BookSlide = ({books}) => {

    if (books.length === 0) {
        return <></>;
    }

    return (
        <OwlCarousel
            className='owl-theme book'
            loop={false} dots={false} nav={true} navContainerClass="d-none"
            margin={10}
            responsive={{
                0: {
                    items: 1,
                    stagePadding: 5
                },
                600: {
                    items: 2,
                    stagePadding: 50
                },
                900: {
                    items: 2,
                    stagePadding: 50
                },
                1000: {
                    items: 3,
                    stagePadding: 50
                },
                1300: {
                    items: 4,
                    stagePadding: 50
                },
                1600: {
                    items: 5,
                    stagePadding: 50
                }
            }}
        >

            {books.map(book => {
                return <CarouselItem
                    book={book}
                />
            })}
        </OwlCarousel>
    )
}

const CarouselItem = ({book}) => {
    return (
        <div className='item bookCategoryItem'>
            <img src={UrlResolver.generateMediaUrl(book.cover)} alt="thumb"/>
            <div className="bookDetail">
                <p>
                    {book.title}
                </p>
                <span className="author-name">by {book.authors.map(author => {
                    return author.name
                }).join(', ')}</span>
                <ButtonSmRound
                    name="Read Elite Summary"
                    link={`/bookDetail/${book.id}`}
                />
            </div>
        </div>
    )
}

export default BookSlide;
