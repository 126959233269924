import React, {useState} from 'react'
import {ListGroup} from 'react-bootstrap';
import {Link} from "react-router-dom";

const CommentAction = (props) => {

    return (
        <ListGroup horizontal className="commentAction">
            <Link to={'#'} onClick={props.like}>
                <div className="iconRound">
                    <img src="../../../../assets/img/like.png" alt="icon"/>
                </div>
                {props.likes} {props.likes === 1 ? 'Like' : 'Likes'}
            </Link>
            {/*<Link to={'#'} onClick={() => {
                setShowReply(true)
            }}>
                <div className="iconRound">
                    <img src="../../../../assets/img/share.png" alt="icon"/>
                </div>
                {props.reply}
            </Link>
            {showReply && <CommentInputCard replyTo={props.id} refresh={props.refresh}/>}*/}

        </ListGroup>
    )
}
export default CommentAction;
