import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

const Footer = (props) => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-items-center">
                    <Col md={2} xs={6}>
                        <ul>
                            <li><Link>Telos.insta</Link></li>
                            <li><Link>Telos.twitter</Link></li>
                        </ul>
                    </Col>
                    <Col md={2} xs={6}>
                        <ul>
                            <li>{!props.user.jwt ? <Link to="/">Home</Link> : <Link to="/home">Home</Link>} </li>
                            <li><Link to="/privacyPolicy">Privacy Policy</Link></li>
                            <li><Link to="/blogs">Blogs</Link></li>
                        </ul>
                    </Col>
                    <Col md={8}>
                        <Link to="/" className="d-flex justify-content-end">
                            <img height="100px" src="../../../../assets/img/telos-logo-1.png" alt="logo"/>
                        </Link>
                    </Col>
                </Row>
                <span>© 2021 Telos. All rights reserved</span>
            </Container>
        </footer>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(Footer);
