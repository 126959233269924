import React from 'react'
import {Route} from 'react-router-dom';
import Landing from "../../main/landing/Landing";
import Header from "../header/Header";
import Footer from "../footer/Footer";

const LandingRoute = () => {
    return (
        <Route render={props => (
            <div className="landingRoute">
                <Header/>
                <div className="main m-0 p-0">
                    <Route exact path="/" component={Landing}/>
                </div>
                <Footer/>
            </div>
        )}
        />
    )
};

export default LandingRoute;
