import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainRoute from "./views/components/routes/MainRoute";
import Home from "./views/main/home/Home";
import Landing from "./views/main/landing/Landing";
import LandingRoute from "./views/components/routes/LandingRoute";
import Login from "./views/auth/login/Login";
import AuthRoute from "./views/components/routes/AuthRoute";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react"; // defaults to localStorage for web
import {persistor, store} from "./store";

const App = (props) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Switch>
                        <LandingRoute path="/" exact name="landing" component={Landing}/>
                        <AuthRoute path="/auth" name="login" component={Login}/>
                        <MainRoute path="/" name="home" component={Home}/>
                    </Switch>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;
