import React, {useState} from 'react';
import {Button, Modal,} from "react-bootstrap";
import SuccessModal from "./components/SuccessModal";
import ErrorModal from "./components/ErrorModal";

const StatusModal = () => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const successModalClose = () => setShowSuccess(false);
    const successModalOpen = () => setShowSuccess(true);


    const errorModalClose = () => setShowError(false);
    const errorModalOpen = () => setShowError(true);

    return (
        <>
            <Button className="smallButton" onClick={successModalOpen}>
                Success Modal
            </Button>
            <Button className="smallButton" onClick={errorModalOpen}>
                Error Modal
            </Button>

            <Modal show={showSuccess} onHide={successModalClose} animation={false} centered>
                <SuccessModal
                    title="Congratulations!"
                    detail="Your payment has been made for Premiuim membership. Please enjoy!"
                    name="Done"
                />
            </Modal>

            <Modal show={showError} onHide={errorModalClose} animation={false} centered>
                <ErrorModal/>
            </Modal>
        </>
    );
}

export default StatusModal;
