import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {Col, Container, NavDropdown, Row} from "react-bootstrap";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import SmallBookThumb from "../../components/sections/smallBookThumb/SmallBookThumb";
import {Api} from "../../../service/Api";
import UrlResolver from "../../../service/UrlResolver";

const BookCategory = (props) => {

    const [books, setBooks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({id: 'all', title: ''});

    let {id} = useParams();

    useEffect(() => {

        if (id == 'all') {
            setCategory({title: 'All', id: 'all'});
            Api.getBooks().then(response => {
                setBooks(response)
            });

        } else {
            Api.getBookCategory(id).then(c => {
                setCategory(c);
            })
            Api.getBookbycategory(id).then(response => {
                setBooks(response)
            })
        }
        Api.getBookCategories().then(response => {
            setCategories([{id: 'all', title: 'All'}, ...response]);
        })

    }, [id]);


    return (
        <div className="blogs">
            <Container>
                <SectionHeading
                    title="Book Club"
                    paddingRight={'0'}
                    rightChild={<>
                        <NavDropdown title={category.title} className="defaultDrop me-2" id="basic-nav-dropdown">
                            {categories.map(c => {
                                return <DropItem link={`/bookCategory/${c.id}`} name={c.title}
                                                 key={`category-${c.id}`}/>
                            })}
                        </NavDropdown>
                    </>
                    }
                />

                <Row>
                    {books.map(book => {
                        return <Col md={3} >
                            <SmallBookThumb
                                key={`book-${book.id}`}
                                thumb={UrlResolver.generateMediaUrl(book.cover)}
                                title={book.title}
                                link={`/bookDetail/${book.id}`}
                                authors={book.authors}
                                tags={book.tags}
                            />
                        </Col>
                    })}

                </Row>
            </Container>
        </div>
    );
}

const DropItem = (props) => {
    return (
        <NavDropdown.Item href={props.link}>{props.name}</NavDropdown.Item>
    )
}
export default BookCategory;
