import React, {useCallback, useEffect, useState} from 'react';
import {Popover} from "react-bootstrap";
import PopoverSticky from "./PopoverSticky";
import PopoverIconsGroup from "./PopoverIconsGroup";
import {Link} from "react-router-dom";
import UrlResolver from "../../../../../service/UrlResolver";
import {Api} from "../../../../../service/Api";
import {connect} from "react-redux";
import Helper from "../../../../../service/Helper";
import DarkBadges from '../../../badges/DarkBadges';

const VideoPopover = ({seek, user, percent, showProgress = false}) => {

    const [liked, setLiked] = useState(false);
    const [inWishlist, setInWishlist] = useState(false);

    useEffect(() => {
        setLiked(user?.likedVideos?.indexOf(seek.id) > -1);
        setInWishlist(user?.wishlistVideos?.indexOf(seek.id) > -1);
    }, [user, seek]);


    const like = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setLiked(!liked);
        Api.likeVideo(seek.id).then((user) => {
        });
    }, [seek, liked])

    const addToWishlist = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setInWishlist(!inWishlist);
        Api.addToWishlist(seek.id).then((user) => {
        });
    }, [seek, inWishlist])


    if (!seek) {
        return <></>
    } else {
        //console.log(seek);
    }
    return (
        <PopoverSticky
            component={
                <PopoverCard
                    like={like}
                    liked={liked}
                    inWishlist={inWishlist}

                    addToWishlist={addToWishlist}
                    thumb={UrlResolver.generateMediaUrl(seek.thumbnail)}
                    popoverTitle={seek.title}
                    videoTags={seek.tags}
                    views={seek.views}
                    detail={Helper.shortenString(seek.description, 100)}
                    link={seek.id}
                />
            }

            placement="auto-end"
            onMouseEnter={() => {
            }}
            delay={200}
        >
            <Link to={`/videoDetail/${seek.id}`} className='item'> 
                <img src={UrlResolver.generateMediaUrl(seek.thumbnail)} alt="thumb"/>
                <div className="thumbOverlay">
                    <div className="videoTitle">
                        {/* <h3>{seek.title}</h3> */}
                    </div>
                    {showProgress &&
                    <div className="px-3">
                        <div className="outerSeek">
                            <div className="innerSeek" style={{width: `${percent}%`}}/>
                        </div>
                    </div>
                    }
                </div>
            </Link>
        </PopoverSticky>


    )
}

const PopoverCard = ({like, liked, addToWishlist, inWishlist, ...props}) => (
    <>
        <Popover.Header className="popHeader">
            <Link to={`/videoDetail/${props.link}`}>
            <div className="popoverBanner">
                <img src={props.thumb} alt="thumb"/>
                <div className="thumbOverlay">
                    <div className="videoTitle new-video-title">
                        <h3>{props.popoverTitle}</h3>
                        
                    </div>
                </div>
            </div>
            </Link>
        </Popover.Header>
        <Popover.Body className="popBody">
            <PopoverIconsGroup
                like={like}
                addToWishlist={addToWishlist}
                liked={liked}
                inWishlist={inWishlist}
                link1={`/videoDetail/${props.link}`}
                link2=""
                link3=""
            />
            {props.views  ?
                <h4>{props.views} Views</h4>
                :
                null
            }
            {/* <h4>{props.views}</h4> */}

            {props.videoTags &&
            <div className="video-tags ">
                            {props.videoTags.map((tag) => {
                                return <DarkBadges badge={tag.title} link={`/videoTags/${tag.title}`}/>
                            })}
                        </div>
                        }
            <p>{props.detail}</p>
            {/* <span className='video-timing-info'>0 of 83 days completed</span> */}
        </Popover.Body>
    </>
)

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPopover);
