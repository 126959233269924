import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Api} from '../../../service/Api';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const PrivacyPolicy = (props) => {

    const [pdetail, setPdetail] = useState(null);


    useEffect(() => {


        Api.getPolicy().then(response => {

            setPdetail(response);

        })

    }, []);

    if (!pdetail) {
        return <></>
    }

    return (
        <div className="blogs blogThumb">
            <Container>
                <Row>
                    <Col md={12}>
                        <h3>{pdetail.title}</h3>

                        <ReactMarkdown children={pdetail.content} rehypePlugins={[rehypeRaw]}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PrivacyPolicy;
