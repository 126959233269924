import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import {Col, Container, NavDropdown, Row} from "react-bootstrap";
import { Api } from '../../../service/Api';
import UrlResolver from "../../../service/UrlResolver";
import BlogHorizontalThumb from "../../components/sections/blogHorizontalThumb/BlogHorizontalThumb";
import BlogVerticalThumb from "../../components/sections/blogVerticalThumb/BlogVerticalThumb";
import Helper from "../../../service/Helper";
import Moment from 'moment';
import { Link } from "react-router-dom";
import SectionHeading from '../../components/sectionHeading/SectionHeading';

const Blogs = (props) => {
    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({id: 'all', name: ''});

    let {id} = useParams();
    useEffect(() => {

        if (id == 'all') {
            setCategory({name: 'All', id: 'all'});
            Api.getBlogs().then(response => {
                let descBlog = response.sort((a, b) => b.id - a.id);
                setBlogs(descBlog);
            })

        } else {
            Api.getBlogCategory(id).then(c => {
                setCategory(c);
            })
            Api.getBlogsbycategory(id).then(response => {
                let descBlog = response.sort((a, b) => b.id - a.id);
                setBlogs(descBlog);
            })
        }
        Api.getBlogCategories().then(response => {
            setCategories([{id: 'all', name: 'All'}, ...response]);
        })

    }, [id]);

    if (!blogs) {
        
        return <></>
    } else {
        console.log(categories);
    }

    return (
        <div className="blogs">
            <Container>
            <SectionHeading
                    title="Blogs"
                    paddingRight={'0'}
                    rightChild={<>
                        <NavDropdown title={category.name} className="defaultDrop me-2" id="basic-nav-dropdown">
                            {categories.map(c => {
                                return <DropItem link={`/blogs/${c.id}`} name={c.name}
                                                 key={`category-${c.id}`}/>
                            })}
                        </NavDropdown>
                    </>
                    }
                />
                {blogs.slice(0, 1).map((blog) => {
                    return <FirstBlog blog={blog} />

                })}
                <div className="section-devider">
                    <img src="../../../../assets/img/vector.png" alt="vector" />
                </div>
                <Row>
                    {blogs.slice(1, 4).map((blog) => {
                        return <Col md={4}>

                            <BlogHorizontalThumb
                                id={blog.id}
                                thumb={UrlResolver.generateBlogMediaUrl(blog.thumbnail.url)}
                                category={blog.blog_category.name}
                                date={Moment(blog.date).format('MMMM DD,YYYY')}
                                title={blog.title}
                                detail={Helper.shortenString(blog.description, 150)}
                            />
                        </Col>

                    })}
                    
                </Row>
                <div className="section-devider">
                    <img src="../../../../assets/img/vector.png" alt="vector" />
                </div>

                <Row>
                    {blogs.slice(4, 10).map((blog) => {
                        return <Col md={6}>
                            <a href={`/blogDetail/${blog.id}`}>
                                <BlogVerticalThumb
                                    thumb={UrlResolver.generateBlogMediaUrl(blog.thumbnail.url)}
                                    category={blog.blog_category.name}
                                    date={Moment(blog.date).format('MMMM DD,YYYY')}
                                    title={blog.title}
                                    detail={Helper.shortenString(blog.description, 70)}
                                />
                            </a>
                        </Col>
                    })}
                    
                </Row>
            </Container>
        </div>
    );
}


const FirstBlog = (props) => {
    return (
        <div className="first-blog">
            <Row>
                <Col md={6}>
                    <div className='first-blog-meta'>
                        <Link to={`/blogDetail/${props.blog.id}`}>
                            <h1>
                                {props.blog.title}
                            </h1>
                            <p className="blog-desc-desktop">{Helper.shortenString(props.blog.description)}</p>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                <h5 className="me-2 first-category">{props.blog.blog_category.name}</h5>
                                    <img className="show-line" src="../../../../assets/img/show-line.png" alt="icon" />
                                </div>
                                <span>{props.blog.date}</span>
                            </div>
                        </Link>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='first-blog-image'>
                        <img src={UrlResolver.generateBlogMediaUrl(props.blog.thumbnail.url)}
                            alt={props.blog.thumbnail.name} />
                    </div>
                    <p className="blog-desc-mobile">{Helper.shortenString(props.blog.description)}</p>
                </Col>
            </Row>
        </div>
    )
}
const DropItem = (props) => {
    return (
        <NavDropdown.Item href={props.link}>{props.name}</NavDropdown.Item>
    )
}
export default Blogs;
