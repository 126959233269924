import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import VideoPlayer from "./components/VideoPlayer";
import AccordinFrame from "../../components/accordinFrame/AccordinFrame";
import VideoDetailCard from "../../components/cards/videoDetailCard/VideoDetailCard";
import VideoDetailTabs from "./components/VideoDetailTabs";
import {Api} from "../../../service/Api";
import UrlResolver from '../../../service/UrlResolver';
import Helper from "../../../service/Helper";
import Moment from 'moment';
import customConfirmDialog from "../../components/CustomConfirmDialog";


const VideoDetail = (props) => {

    const [progress, setProgress] = useState(0);
    const [videodetail, setVideodetail] = useState(null);
    const [courseVideoId, setCourseVideoId] = useState('');
    const [courseAudioId, setCourseAudioId] = useState('');
    const [currentLesson, setCurrentLesson] = useState(null);
    const [similarVideos, setSimilarVideos] = useState([]);


    const [currentSubscription, setCurrentSubscription] = useState({});

    useEffect(() => {
        Api.currentSubscription().then(response => {
            setCurrentSubscription(response)
        }).catch(e => {
            setCurrentSubscription(null)
        })
    }, []);

    const playLesson = (lesson, audio = false) => {
        setCurrentLesson(lesson);
        if (!audio) {
            setCourseVideoId(lesson.vimeoid);
        } else {
            if (!lesson.audio_file) {
                alert("Content not found");
            } else {
                setCourseAudioId(`${Api.base_url}${lesson.audio_file.url}`)
            }

        }
    }

    const handleCourseClick = (lesson) => {
        playLesson(lesson)
    }

    let {id} = useParams();

    const loadVideo = (id) => {
        Api.getVideo(id).then(response => {
            setVideodetail(response)
            Api.getCategory(response.category.id).then(response=>{
                setSimilarVideos(response.videos.filter(tvideo => {
                    return tvideo.id != id;
                }));
            });
        })

    }
    useEffect(() => {
        loadVideo(id)

    }, [id]);

    const refreshComments = () => {
        loadVideo(id)
    }

    const onPlay = () => {
        if(videodetail.sections.length === 0) {
            setCourseVideoId(videodetail.vimeoId);

        } else {
            for (let i = 0; i < videodetail.sections.length; i++) {
                for (let j = 0; j < videodetail.sections[i].lessons.length; j++) {
                    playLesson(videodetail.sections[i].lessons[j]);
                    return;
                }
            }
            alert("Content not found");
        }
       
    }

    const history = useHistory();

    const onTimeUpdate = (t) => {
        if (!currentSubscription && t.seconds > 30) {
            setCourseVideoId(null);
            setCourseAudioId(null)
            customConfirmDialog("Subscription needed", "You need to subscribe to be able to watch full videos.", () => {
                history.push("/membership");
            }, "Subscribe", () => {

            }, "Cancel", "error")
            return;
        }

        let {percent, seconds} = t;
        if (percent < progress + 0.1 && percent < 1) {
            return;
        }
        setProgress(percent);

        Api.updateVideoProgress(currentLesson.id, {
            percent: percent * 100,
            seconds
        }).then(console.log).catch(console.log);
    }

    const openVideo = (video)=>{
        history.push(`/videoDetail/${video.id}`)
    }

    const onPlayAudio = () => {
        if(videodetail.sections.length > 0){
            for (let i = 0; i < videodetail.sections.length; i++) {
                for (let j = 0; j < videodetail.sections[i].lessons.length; j++) {
                    playLesson(videodetail.sections[i].lessons[j], true);
                    return;
                }
            }
        } else if(videodetail.audio_file){
            setCurrentLesson(videodetail);
            setCourseAudioId(`${Api.base_url}${videodetail.audio_file.url}`)
             return;
        } else {
            alert("Content not found");
        }
    }

    if (!videodetail) {
        return <></>
    } else {
        console.log(videodetail);
    }
    return (
        <Container className="mt-5">
            <Row>
                <Col md={9}>
                    <VideoPlayer onPlay={onPlay}
                                 onTimeUpdate={onTimeUpdate}
                                 onPlayAudio={onPlayAudio}
                                 video={courseVideoId}
                                 audio={courseAudioId}
                                 thumb={videodetail.large_thumbnail ? UrlResolver.generateBlogMediaUrl(videodetail.large_thumbnail.url) : '../../../assets/home-images/wake-up.png'}
                                 title={videodetail.title}
                                //  category={`${videodetail.category.title}`}
                    />

                    <div className="mt-3">
                        <VideoDetailCard
                            totalLikes={videodetail.likedByUsers.length}
                            id={videodetail.id}
                            videoTags={videodetail.tags}
                            notes={videodetail.notes}
                            title={videodetail.title}
                            view={videodetail.views}
                            time={Moment(videodetail.created_at).format('MMMM DD,YYYY')}
                            detail={Helper.shortenString(videodetail.description)}
                        />
                    </div>

                    <div className="section-devider my-4">
                        <img src="../../../../assets/img/vector.png" alt="devider"/>
                    </div>

                    <VideoDetailTabs inform={videodetail.information} refreshComments={refreshComments}
                                     video={videodetail} lesson={videodetail.sections}
                                     show_without_sections={videodetail.show_without_sections}
                                     onhandle={handleCourseClick}/>
                </Col>
                {videodetail.sections.length > 0 &&
                <Col md={3} className="video-accordin">
                    <AccordinFrame lesson={videodetail.sections}
                                   show_without_sections={videodetail.show_without_sections}
                                   onhandle={handleCourseClick}/>

                </Col>
                }
                {videodetail.sections.length === 0 &&
                <Col md={3} className="video-accordin">
                    {similarVideos.map(video=>{
                        return <div className={'accordion-body no-sections'}>

                            <div className="d-flex flex-row align-items-center only-lessons mb-3"
                                 style={{cursor: 'pointer'}}
                                 onClick={() => openVideo(video)}>
                                <img src={UrlResolver.generateBlogMediaUrl(video.thumbnail.url)} alt="thumb"/>
                                {/* <p className="photo-overlay ramesh">{video.title}</p> */}
                            </div>
                        </div>
                    })}
                </Col>
                }

            </Row>
        </Container>
    );
}

export default VideoDetail;
