import React from 'react'
import {Nav} from 'react-bootstrap';

const CommentButton = (props) => {
    return (
        <Nav.Link href={props.link} onClick={props.onClick}
                  className={props.back ? "commentButton" : "commentSimpleButton"}>
            {props.name}
        </Nav.Link>
    )
}
export default CommentButton;
