import {Api} from "./Api";

export default class UrlResolver {

    static generateMediaUrl(path) {
        if (!path) {
            return null;
        }
        return `${Api.base_url}${path.url}`;

        //return `http://localhost:1337${path}`;
    }

    static generateBlogMediaUrl(path) {
        if (!path) {
            return null;
        }
        return `${Api.base_url}${path}`;

        //return `http://localhost:1337${path}`;
    }
}
