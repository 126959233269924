import React, {useState} from 'react';
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Api} from "../../../../service/Api";
import {Form} from "react-bootstrap";
import ButtonRound from "../../buttons/ButtonRound";
import {useHistory} from "react-router-dom";
import customConfirmDialog from "../../CustomConfirmDialog";
import SimpleInput from '../../inputs/SimpleInput';

const Stripe = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [billingDetails, setBillingDetails] = useState({
        // email: "",
        // phone: "",
        name: ""
    });

    const history = useHistory();

    const processPayment = async (event) => {
        setLoading(true);
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: billingDetails
        });

        if (error) {
            setLoading(false);
            customConfirmDialog("Error!", "Your payment attempt has failed, please check the details and try again!", () => {

            }, "Okay", null, null, 'error');
        } else {

            console.log('[PaymentMethod]', paymentMethod);
            handleSubmit(event, paymentMethod)
        }
    };


    const handleSubmit = (event, paymentMethod) => {
        event.preventDefault();

        const subPrice = props.plan.price;
        const subPlan = props.plan.plan;

        var data = {
            paymentResponse: {
                "payment": "done",
                paymentMethod
            },
            membership: subPlan,
            amount: subPrice,
            paymentMethod: paymentMethod
        }


        Api.subscription(data).then(response => {
            props.closeModal();
            customConfirmDialog("Congratulations!", "You have successfully subscribed to Gold membership.", () => {
                history.push("/home")
            }, "Okay");
        }).catch(err => {
            console.log(err.response.data);
            alert('An error has occurred. Please try again later');
        })
            .finally(() => {
                console.log('done');
            })
    }

    return <Form onSubmit={processPayment}>
        
        <SimpleInput className="form-control"  name="name" type="text"  placeholder="Card holder name" onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}/>
                 {/* <SimpleInput className="form-control"  name="email" type="email"  placeholder="Email" onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value });
          }}/>
                 <SimpleInput className="form-control"  name="phone" type="text"  placeholder="Phone Number" onChange={(e) => {
            setBillingDetails({ ...billingDetails, phone: e.target.value });
          }}/> */}
        <CardElement
            options={{
                style: {
                    base: {
                        fontSize: '16px',
                        color: '#000000',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#000000',
                        '::placeholder': {
                            color: '#000000',
                        },
                    },
                    invalid: {
                        color: '#9e2146',
                    },
                },
            }}
        />
        {/* <SimpleInput className="form-control"   type="text" placeholder="Card number"/>
                    <SimpleInput className="form-control"   type="text" placeholder="Card holder name"/>
                    <div className="col-md-6 pl-0">
                        <SimpleInput className="form-control"   type="text" placeholder="Expiry month"/>
                    </div>
                    <div className="col-md-6 pr-0   ">
                        <SimpleInput className="form-control"   type="text" placeholder="Cvv"/>
                    </div>                */}
        {/* <Link onClick={props.showNext} className="defaultButton">Pay Now</Link> */}
        <div className="button-col payment">
            <ButtonRound
                name="Pay Now"
                onClick={processPayment}
                loading={loading}
            />
        </div>
        {errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
    </Form>

}

export default Stripe;
