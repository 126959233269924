import React from 'react'
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import ButtonSm from "../../components/buttons/ButtonSm";
import ButtonSmRound from "../../components/buttons/ButtonSmRound";
import CommentButton from "../../components/buttons/CommentButton";
import DefaultButton from "../../components/buttons/DefaultButton";
import IconBorderButton from "../../components/buttons/IconBorderButton";
import IconButton from "../../components/buttons/IconButton";
import PlayerButton from "../../components/buttons/PlayerButton";
import TextButton from "../../components/buttons/TextButton";
import ButtonRound from "../../components/buttons/ButtonRound";
import VideoDetailCard from "../../components/cards/videoDetailCard/VideoDetailCard";
import {Container} from "react-bootstrap";
import CommentInputCard from "../../components/cards/commentInputCard/CommentInputCard";
import CommentCard from "../../components/cards/commentCard/CommentCard";
import AccordinFrame from "../../components/accordinFrame/AccordinFrame";
import StatusModal from "../../components/modals/statusModal/StatusModal";
import PaymentModal from "../../components/modals/paymentModal/PaymentModal";
import DefaultInput from "../../components/inputs/DefaultInput";
import PaymentInput from "../../components/inputs/PaymentInput";
import PaymentSmallInput from "../../components/inputs/PaymentSmallInput";
import BlogHorizontalThumb from "../../components/sections/blogHorizontalThumb/BlogHorizontalThumb";
import BlogVerticalThumb from "../../components/sections/blogVerticalThumb/BlogVerticalThumb";

const Elements = (props) => {
    return (
        <Container>
            <SectionHeading
                title="Buttons"
            />
            <div className="d-flex justify-content-start flex-wrap">
                <ButtonSm
                    name="Small Button"
                />
                <ButtonSmRound
                    name="Small Round Button"
                />
                <ButtonRound
                    name="Round Button"
                />
                <CommentButton
                    name="Comment Button"
                />
                <DefaultButton
                    name="Default Button"
                />
                <IconBorderButton
                    icon="../../../../assets/img/watchlist.svg"
                    name="Icon Border Button"
                />
                <IconButton
                    icon="../../../../assets/img/play-yellow.png"
                    name="Icon Button"
                />
                <PlayerButton
                    icon="../../../../assets/img/play-ico.svg"
                    name="Player Button"
                />
                <TextButton
                    name="Normal Text Button"
                />
            </div>
            <div>
                <DefaultInput/>
                <PaymentInput/>
                <PaymentSmallInput/>
            </div>

            <SectionHeading
                title="Video Detail Card"
            />
            <VideoDetailCard
                title="Title"
                view="31,104 views"
                time="May 25,2021"
                detail="The trailer of Panther a Bangla movie starring Jeet and Shraddha Das in the lead role.
                The movie is directed by Anshuman Pratyush The trailer of Panther a Bangla movie."
            />

            <CommentInputCard/>

            <CommentCard
                userName="Esther Howard"
                time="time"
                comment="@channel We’ve updated the open enrollment policies that our company supports based on the
                new changes. You’ll find more information on the internal help page. These changes won’t take e..."
                like="21" reply="View 7 replies"
            />

            <AccordinFrame
                thumb="../../../../assets/img/frame1.png"
                title="Day 1: Dont loose hope"
            />

            <StatusModal/>
            <PaymentModal/>

            <BlogHorizontalThumb
                thumb="../../../../assets/img/frame1.png"
                category="Mental health"
                date="23rd May 2021"
                title="Winter Car Seat Safety Tips"
                detail="Customised videos just for you  Customised videos just for you "
            />

            <BlogVerticalThumb
                thumb="../../../../assets/img/frame1.png"
                category="Mental health"
                date="23rd May 2021"
                title="Winter Car Seat Safety Tips"
                detail="Customised videos just for you  Customised videos just for you "
            />
        </Container>
    );
}

export default Elements;
