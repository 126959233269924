import React, {useEffect, useState} from 'react'
import {Link, useParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import SmallVideoThumb from "../../components/sections/smallVideoThumb/SmallVideoThumb";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import {Api} from '../../../service/Api';

const VideoTag = (props) => {

    const [tagvideos, setTagVideos] = useState(null);

    let {name} = useParams();

    useEffect(() => {
        Api.getVideos().then(response => {

            setTagVideos(response.filter(tvideo => {
                return tvideo.tags.length > 0 && tvideo.tags.find(o => o.title === name);

            }));
        })

    }, [name]);

    if (!tagvideos) {
        return <></>
    } else {
        console.log(tagvideos);
    }
    return (
        <div className="blogs">
            <Container>
                <SectionHeading
                    title={name}
                />
                <Row>
                    {tagvideos.map(video => {
                        return <Col md={3}>
                            <Link to={`/videoDetail/${video.id}`}>
                                <SmallVideoThumb
                                    video={video}
                                />
                            </Link>
                        </Col>
                    })}
                </Row>
            </Container>
        </div>
    );
}
export default VideoTag;
