import React from 'react'
import {Accordion, Col, Container, Row} from "react-bootstrap";

const FAQ = (props) => {
    if (!props.faqs) {
        return <></>
    }
    return (
        <div className="section laningSectionDark faqSection">
            <Container>
                <div className="faqHeading">
                    Frequently asked questions
                </div>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Accordion>
                            {props.faqs.map((faq, index) => {
                                return <FaqItem
                                    eventKey={index}
                                    heading={faq.question}
                                    detail={faq.answer}
                                />
                            })}

                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const FaqItem = (props) => {
    return (
        <Accordion.Item eventKey={props.eventKey}>
            <Accordion.Header>{props.heading}</Accordion.Header>
            <Accordion.Body>
                <div className="d-flex flex-row align-items-center">
                    <p className="ms-2">{props.detail}</p>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default FAQ;
