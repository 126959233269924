import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Api} from "../../../../../service/Api";
import customConfirmDialog from "../../../../components/CustomConfirmDialog";
import {connect} from "react-redux";

const BillingMethodTab = (props) => {

    const history = useHistory();

    const [currentSubscription, setCurrentSubscription] = useState({});

    useEffect(() => {
        Api.currentSubscription().then(response => {
            setCurrentSubscription(response)
        }).catch(e => {
            setCurrentSubscription(null)
        })
    }, []);


    const onCancel = () => {
        customConfirmDialog("Are you sure", "Are you sure you want to cancel your subscriptions?", () => {
            Api.cancelSubscription().then(() => {
                customConfirmDialog("Success", "Your subscription has been cancelled. You will not be charged any more.", () => {
                    history.push("/home")
                }, "Okay");
            }).catch(() => {
                customConfirmDialog("Error", "An error has occurred, please try again later.", () => {

                }, "Okay");
            })
        }, "Yes", () => {

        }, "No")
    }
    if (currentSubscription === null) {
        return (
            <div className="billingMethod">

                <div className="d-flex flex-column mt-3 payment-mobile">
                    <Link to="#" className="align-self-center cancelMemberShipLink">
                        No active subscriptions found
                    </Link>
                    <Link to="/membership" className="mt-3 text-center upgradeButton">
                        Click here to subscribe
                    </Link>
                </div>
            </div>
        )
    }
    if (Object.keys(currentSubscription).length === 0) {
        return <></>
    }
    return (
        <div className="billingMethod">
            <div className="billingMethodCard">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                        <img height="24px" src="../../../../assets/img/credit-card.png" alt="icon"/>
                        <h4 className="mb-0 " style={{marginLeft: 10}}>Credit Card</h4>
                    </div>
                    <img height="24px" src="../../../../assets/img/credit-card.png" alt="icon"/>
                </div>
                {/* <h4>**** ***** **** {currentSubscription.paymentResponse.paymentMethod.card.last4}</h4> */}
            </div>
            <div className="d-flex flex-column mt-3 payment-mobile">
                {currentSubscription && currentSubscription.membership && currentSubscription.membership.type === 'monthly' &&
                <Link to="/membership" className="align-self-center mb-2 upgradeButton for-mobile">
                    Upgrade to yearly
                </Link>
                }
                <Link onClick={onCancel} to="#" className="align-self-center cancelMemberShipLink">
                    Cancel subscription
                </Link>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};


export default connect(mapStateToProps)(BillingMethodTab);
