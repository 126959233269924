import React from "react";
import UrlResolver from "../../../../service/UrlResolver";
import VideoPopover from "../../sliders/videoSlide/components/VideoPopover";

const SmallVideoThumb = (props) => {
    if (props.video) {
        return <div className='item smallThumb'>
            <VideoPopover
                title={props.video.title}
                seek={props.video}
                slider={false}
                showProgress={props.showProgress}
                thumb={UrlResolver.generateMediaUrl(props.video.thumbnail)}
            />
        </div>
    }
    return (
        <div className='item smallThumb inter-mobile-new'>
            <img className="not-for-mobile" src={props.thumb} alt="thumb"/>
            <div className="thumbOverlay">
                <div className="videoTitle">
                    <h3>{props.title}</h3>
                    <img className="for-mobile-only" src='../../../../assets/img/select-inter.svg' alt="thumb"/>
                </div>
                {props.showProgress &&
                <div className="px-3">
                    <div className="outerSeek">
                        <div className="innerSeek"/>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}
export default SmallVideoThumb;
