import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Moment from 'moment';

const PaymentHistoyrTab = (props) => {
    if (!props.user.subscriptions) {
        return <></>;
    }

    return (
        <div className="aymentHistoyr">
            <table className="table responsive" style={{color: 'white'}}>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Description</th>
                    {/* <th>Payment Status</th> */}
                    {/* <th>Receipt</th> */}
                </tr>
                </thead>
                <tbody>
                {props.user.subscriptions.reverse().map((item, index) => {
                    return <tr>
                        <td>{Moment(item.created_at).format('MMMM DD,YYYY')}</td>
                        <td style={{width: 100}}>
                            <div className="price-currency">
                                <p>£{item.amount}</p>
                            </div>
                        </td>
                        <td>{item.membership ?
                            `${item.membership == 2 ? "Basic" : "Gold"}`
                            : ''}</td>
                        {/* <td>{item.paymentStatus}</td> */}

                        {/* <td><Link className="payment-view">View</Link></td> */}
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};


export default connect(mapStateToProps)(PaymentHistoyrTab);

