import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ButtonSmRound from "../../buttons/ButtonSmRound";
import UrlResolver from "../../../../service/UrlResolver";

const BlogSlide = ({blogs}) => {

    if (blogs.length === 0) {
        return <></>;
    }

    return (
        <OwlCarousel
            className='owl-theme book'
            loop={false} dots={false} nav={true} navContainerClass="d-none"
            margin={10}
            responsive={{
                0: {
                    items: 1,
                    stagePadding: 5
                },
                600: {
                    items: 2,
                    stagePadding: 50
                },
                900: {
                    items: 2,
                    stagePadding: 50
                },
                1000: {
                    items: 3,
                    stagePadding: 50
                },
                1300: {
                    items: 4,
                    stagePadding: 50
                },
                1600: {
                    items: 5,
                    stagePadding: 50
                }
            }}
        >

            {blogs.map(blog => {
                return <CarouselItem
                    blog={blog}
                />
            })}
        </OwlCarousel>
    )
}

const CarouselItem = ({blog}) => {
    return (
        <div className='item bookCategoryItem'>
            <img src={UrlResolver.generateBlogMediaUrl(blog.thumbnail.url)} alt="thumb"/>
            <div className="bookDetail">
                <p>
                    {blog.title}
                </p>
                <span className="author-name">{blog.blog_category.name}</span>
                <ButtonSmRound
                    name="Read More"
                    link={`/blogDetail/${blog.id}`}
                />
            </div>
        </div>
    )
}

export default BlogSlide;
