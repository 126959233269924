import React from 'react'
import { Link, Route } from 'react-router-dom';
import Login from "../../auth/login/Login";
import Register from "../../auth/register/Register";
import { Col, Container, Row } from "react-bootstrap";
import Forgot from "../../auth/forgot/Forgot";
import CreatePassword from "../../auth/createPassword/CreatePassword";

const AuthRoute = ({ component: Component }) => {

    return (
        <Route render={props => (
            <div className="auth-screens">
                <Container>
                    <div className='new-login-design'>
                        <Row>
                            <Col xl={6} className="d-flex justify-content-center align-items-center">
                                <div className="authLeftSection ">
                                    <Link to="/" className="mb-3">
                                        <img src="../../../../assets/img/logo-2.png" alt="logo" />
                                    </Link>
                                    {/* <p>
                                    The only platform of its kind in UK, unlimited premium videos and access from
                                    anywhere
                                    and anytime
                                </p> */}
                                </div>
                            </Col>
                            <Col xl={6} className="d-flex justify-content-center">
                                <div className="authRightSection">
                                    <Route exact path="/auth" component={Login} />
                                    <Route path="/auth/register" component={Register} />
                                    <Route path="/auth/forgot" component={Forgot} />
                                    <Route path="/auth/createPassword" component={CreatePassword} />

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        )}
        />
    )
};

export default AuthRoute;
