import React, {useState} from 'react';
import {Modal,} from "react-bootstrap";
import AddCardModal from "./components/AddCardModal";
import PayCardModal from "./components/PayCardModal";

const PaymentModal = () => {
    const [addCard, setAddCard] = useState(false);
    const [payCard, setPayCard] = useState(true);

    const hideAddCard = () => setAddCard(true);

    const hidePayCard = () => setPayCard(true);


    return (
        <>
            <Modal show={addCard} onHide={hideAddCard} animation={false} centered>
                <AddCardModal
                    icon="../../../../assets/img/credit-card.png"
                    title="Credit Card"
                    detail="Your payment has been made for Premiuim membership. Please enjoy!"
                    name="Done"
                />
            </Modal>

            <Modal show={payCard} onHide={hidePayCard} animation={false} centered>
                <PayCardModal
                    icon="../../../../assets/img/credit-card.png"
                    title="Credit Card"
                    detail="Your payment has been made for Premiuim membership. Please enjoy!"
                    name="Done"
                />
            </Modal>
        </>
    );
}

export default PaymentModal;
