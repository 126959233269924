import React, { useEffect, useState } from 'react';
import { Api } from "../../../service/Api";

const WebinarDetail = ({ match }) => {
    let { id } = match.params;

    const [webinar, setWebinar] = useState(null);

    useEffect(() => {
        Api.getWebinarDetail(id).then(response => {
            setWebinar(response)
        })
    }, [id])

    if (!webinar) {
        return <></>
    }
    return <>
        <div className='webinar-details'>
            <div className='video-webinar'>
                <iframe src={`https://vimeo.com/event/${webinar.vimeoId}/embed`} frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                    style={{ width: '100%', height: 'calc(60vh)' }}></iframe>
            </div>
            <div className='chat-webinar'>

                <iframe src={`https://vimeo.com/event/${webinar.vimeoId}/chat/`} style={{ width: '100%', height: 'calc(60vh)' }} frameBorder="0"></iframe>
            </div>
        </div>
    </>
}

export default WebinarDetail;
