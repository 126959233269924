import React, {useEffect, useState} from 'react'
import {Container, Nav, Navbar} from 'react-bootstrap';
import DefaultDropdown from "../dropdown/DefaultDropdown";
import HeaderSearch from "./components/HeaderSearch";
import UserDropdown from "../dropdown/UserDropdown";
import MobileHeader from "./components/MobileHeader";
import {connect} from "react-redux";
import HeaderWithoutLogin from "./HeaderWithoutLogin";
import {Api} from "../../../service/Api";
import NotificationDropdown from "../dropdown/NotificationDropdown";

const Header = ({updateUser, updateCategories, login, ...props}) => {
    useEffect(() => {
        if (props.user.jwt) {
            Api.getCategories().then(categories => {
                updateCategories(categories)
            })

            Api.getProfile().then(user => {
                updateUser(user);
            }).catch(e => {
                login({jwt: null, user: null})
            })
        }
    }, [props.user.jwt, login, updateUser, updateCategories
    ])

    console.log(props.user)
    if (!props.user.jwt) {
        return <HeaderWithoutLogin/>
    }
    return (
        <header className="header">
            <DefaulHeader categories={props.categories} user={props.user}
                          logo="../../../../assets/img/logo-2.png"
            />
            <MobileHeader categories={props.categories} user={props.user ? props.user.user : null}
                          logo="../../../../assets/img/logo-text.png"
            />
        </header>
    )
}

const DefaulHeader = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [hasUnread, setHasUnread] = useState(false);
    useEffect(() => {
        Api.getNotifications().then(response => {
            setNotifications(response);
            response.map(notification => {
                if (props.user.user) {
                    let userTs = Number(props.user.user.notifications_read_at);
                    let notificationTs = Date.parse(notification.published_at);
                    if (notificationTs > userTs) {
                        setHasUnread(true)
                    }
                }
            });
        })
    }, []);


    return (
        <Navbar bg="dark" expand="lg" className="pcHeader">
            <Container>
                <Navbar.Brand href={props.user ? '/home' : '/'}>
                    <img height="50" src={props.logo} alt="logo"/>
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse className="justify-content-end">
                    <Nav className="align-items-center">
                        <HeaderSearch icon="../../../../assets/img/search-white.png"/>
                        <DefaultDropdown categories={props.categories}/>
                        <NotificationDropdown notifications={notifications}
                                              notification={hasUnread ? '../../../../assets/img/notifications-unread.svg' : '../../../../assets/img/notifications.svg'}
                        />
                        <UserDropdown
                            user={props.user ? props.user.user : null}
                        />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        categories: state.categories
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateCategories: (categories) => dispatch({type: "UPDATE_CATEGORIES", data: categories}),
        updateUser: (user) => dispatch({type: "UPDATE_USER", data: user}),
        login: (user) => dispatch({type: "LOGIN", data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header)
