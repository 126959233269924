import React, {useCallback, useEffect, useState} from 'react'
import {ListGroup} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Api} from "../../../../../service/Api";

const IconsGroup = ({user, id, totalLikes, notes}) => {


    const [total, setTotal] = useState(totalLikes);
    const [note, setNote] = useState(notes);
    const [liked, setLiked] = useState(false);
    const [inWishlist, setInWishlist] = useState(false);

    useEffect(() => {
        setLiked(user?.likedVideos?.indexOf(id) > -1);
        setInWishlist(user?.wishlistVideos?.indexOf(id) > -1);
    }, [user, id]);


    const like = useCallback((e) => {
        e.preventDefault();
        setLiked(!liked);
        Api.likeVideo(id).then((video) => {
            setTotal(video.likedByUsers.length)
        });
    }, [id, liked])

    const addToWishlist = useCallback((e) => {
        e.preventDefault();
        setInWishlist(!inWishlist);
        Api.addToWishlist(id).then((user) => {
        });
    }, [id, inWishlist])

    return (
        <ListGroup horizontal className="iconGroup">
            <Link onClick={addToWishlist}>
                {inWishlist ? <div className="iconRound active">
                        <img src="../../../../assets/img/tick.svg" alt="icon"/>
                    </div> :
                    <div className="iconRound">
                        <img src="../../../../assets/img/v2/plus.svg" alt="icon"/>
                    </div>}
                Watchlist
            </Link>
            <Link onClick={like}>
                {liked ? <div className="iconRound active">
                        <img src="../../../../assets/img/v2/heart-active.svg" alt="icon"/>
                    </div> :
                    <div className="iconRound">
                        <img src="../../../../assets/img/v2/heart.svg" alt="icon"/>
                    </div>}
                {total} Likes
            </Link>
            {note !== null ?
                <a href={`${Api.base_url}${note.url}`} target="_blank">
                    <div className="iconRound">
                        <img src="../../../../assets/img/download.png" alt="icon"/>
                    </div>
                    Notes
                </a>
                : ''}
        </ListGroup>
    )
}
const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCategories: (categories) => dispatch({type: "UPDATE_CATEGORIES", data: categories}),
        updateUser: (user) => dispatch({type: "LOGIN", data: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconsGroup);
