import React from "react";
import DarkBadges from "../../badges/DarkBadges";
import ButtonSmRound from "../../buttons/ButtonSmRound";

const SmallBookThumb = (props) => {

    return (
        <div className='item bookCategoryItem bookclub'>
            <img src={props.thumb} alt="thumb"/>
            <div className="bookDetail">
                <p>
                    {props.title}
                </p>
                {props.authors &&
                <span className="author-name">by {props.authors.map(author => {
                    return author.name
                }).join(', ')}</span>
                }
                <ButtonSmRound
                    name="Read Elite Summary"
                    link={props.link}
                />
                <div className="video-tags all-category">
                    {props.tags.map((tag) => {
                        return <DarkBadges badge={tag.title} link={`/booktags/${tag.title}`}/>
                    })}
                </div>
            </div>
        </div>
    )
}
export default SmallBookThumb;
