import React from 'react'
import {Nav} from 'react-bootstrap';

const TextButton = (props) => {
    return (
        <Nav.Link href={props.link} className="textButton">
            {props.name}
        </Nav.Link>
    )
}
export default TextButton;
