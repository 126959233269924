import axios, {AxiosResponse} from 'axios';
import {Book, Category} from "../types/Types";
import {store} from "../store";


const responseBody = (response: AxiosResponse) => response.data;


const createInstance = (authorize = true, multipart = false) => {

    let token = store.getState().user.jwt;

    const headers: Record<string, string> = {
        Accept: 'application/json',
    };
    if (authorize && token) {
        headers.Authorization = `Bearer ${token}`;
    }
    if (multipart) {
        headers['content-type'] = 'multipart/form-data';
    }
    console.log({headers});

    return axios.create({
        baseURL: Api.base_url,
        timeout: 15000,
        headers
    });
}
const requests = {
    get: (url: string, authorize = true) => {
        return createInstance(authorize).get(url).then(responseBody)
    },
    post: (url: string, body: {}, authorize = true, multipart = false) => {
        return createInstance(authorize, multipart).post(url, body).then(responseBody)
    },
    put: (url: string, body: {}, authorize = true) => {
        return createInstance(authorize).put(url, body).then(responseBody)
    },
    delete: (url: string, authorize = true) => {
        return createInstance(authorize).delete(url).then(responseBody)
    },
};

export const Api = {
    base_url: 'https://backend.telos.itmatic.io',
    //base_url: 'http://localhost:1337',

    getCategories: (): Promise<Category[]> => requests.get('/categories?_sort=position:ASC'),
    getFaqs: (): Promise<Category[]> => requests.get('/faqs'),
    getTestimonials: (): Promise<Category[]> => requests.get('/testimonials'),
    getMemberships: (): Promise<Category[]> => requests.get('/memberships'),
    getVideos: (): Promise<Category[]> => requests.get('/videos'),
    getFeaturedVideos: (): Promise<Category[]> => requests.get('/videos?featured=1'),
    getContinueWatching: (): Promise<Category[]> => requests.get('/videos/continue'),
    getTagBooks: (): Promise<Category[]> => requests.get('/books'),
    getWatchlist: (): Promise<Category[]> => requests.get('/videos/watchlist'), 
    getPolicy: (): Promise<Category[]> => requests.get('/privacy-policy'),
    searchCategories: (query: string): Promise<Category[]> => requests.get(`/categories?title_contains=${query}`),
    searchVideos: (query: string): Promise<Category[]> => requests.get(`/videos?title_contains=${query}`),
    searchWebinars: (query: string): Promise<Category[]> => requests.get(`/webinars?title_contains=${query}`),
    searchBooks: (query: string): Promise<Category[]> => requests.get(`/books?title_contains=${query}`),
    searchBlogsCategories: (query: string): Promise<Category[]> => requests.get(`/blog-categories?name_contains=${query}`),
    searchBooksCategories: (query: string): Promise<Category[]> => requests.get(`/book-categories?title_contains=${query}`),
    // searchBlogsTags: (query: string): Promise<Category[]> => requests.get(`/books?title_contains=${query}`),
    // searchBooksTags: (query: string): Promise<Category[]> => requests.get(`/booktags/${query}`),
    // searchVideosTags: (query: string): Promise<Category[]> => requests.get(`/videoTags/${query}`),
    // searchWebinarsTags: (query: string): Promise<Category[]> => requests.get(`/webinar/${query}`),
    getBlogs: (): Promise<Category[]> => requests.get('/blogs'),
    getWebinars: (): Promise<Category[]> => requests.get('/webinars'),
    getWebinarDetail: (id: string | number): Promise<Category[]> => requests.get(`/webinars/${id}`),
    searchBlogs: (query: string): Promise<Category[]> => requests.get(`/blogs?title_contains=${query}`),
    getBlogdetail: (id: string | number): Promise<Category[]> => requests.get(`/blogs/${id}`),
    getBookdetail: (id: string | number): Promise<Category[]> => requests.get(`/books/${id}`),
    getCategory: (id: string | number): Promise<Category[]> => requests.get(`/categories/${id}`),
    getBookCategory: (id: string | number): Promise<Category[]> => requests.get(`/book-categories/${id}`),
    getBookCategories: (id: string | number): Promise<Category[]> => requests.get(`/book-categories`),
    getBookbycategory: (id: string | number): Promise<Category[]> => requests.get(`/books?book_category.id=${id}`),
    getVideo: (id: string | number): Promise<Category[]> => requests.get(`/videos/${id}`),
    getBlogCategory: (id: string | number): Promise<Category[]> => requests.get(`/blog-categories/${id}`),
    getBlogsbycategory: (id: string | number): Promise<Category[]> => requests.get(`/blogs?blog_category.id=${id}`),
    getBlogCategories: (id: string | number): Promise<Category[]> => requests.get(`/blog-categories`),


    updateProfile: (params: Map<string, any>) => {
        return requests.post("/users/me", params);
    },
    updateNotificationOpenTimestamp: (timestamp: number) => {
        return requests.post("/users/me/notifications", {timestamp});
    },
    getProfile: () => {
        return requests.get("/users/me");
    },

    getRecommendedVideos: (user: any) => {
        return requests.get(`/videos?${user.interests.map((id: number) => {
            return `category.id_in=${id}`;
        }).join('&')}&_sort=id:DESC`);
    },

    login: (params: Map<string, any>) => {
        return requests.post('/auth/local', params, false);
    },

    subscription(params: Map<string, any>) {
        return requests.post('/subscriptions', params);
    },

    cancelSubscription() {
        return requests.post('/subscriptions/cancel', {});
    },

    currentSubscription() {
        return requests.get('/subscriptions/current');
    },

    changePassword(params: Map<string, any>) {
        return requests.post('/auth/reset-password', params, false);
    },

    likeVideo(id: string | number) {
        return requests.post(`/videos/${id}/like`, {});
    },
    likeComment(id: string | number) {
        return requests.post(`/comments/${id}/like`, {});
    },

    postComment(id: string | number, comment: string) {
        return requests.post(`/videos/${id}/comment`, {comment});
    },

    uploadFile(data: FormData) {
        return requests.post(`/upload`, data, true, true);
    },

    saveInterests(ids: number[]) {
        return requests.post(`/users/interests`, {interests: ids});
    },

    addToWishlist(id: string | number) {
        return requests.post(`/videos/${id}/wishlist`, {});
    },

    updateVideoProgress(id: string | number, params: Map<string, any>) {
        return requests.post(`/videos/${id}/progress`, params);
    },

    register(params: Map<string, any>) {
        return requests.post('/auth/local/register', params, false);
    },

    forgetPassword(params: Map<string, any>) {
        return requests.post('/auth/forgot-password', params, false);
    },

    newPassword(params: Map<string, any>) {
        return requests.post('/password', params, false);
    },

    getBooks: (): Promise<Book[]> => requests.get('/books'),

    getBrowseItems() {
        return requests.get('/browse-items?_sort=position:ASC');
    },
    getNotifications() {
        return requests.get('/notifications?_sort=id:DESC');
    }
};


